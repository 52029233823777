import {
  AppBar,
  Box,
  Collapse,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  useScrollTrigger,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MobileMenu } from "src/components/MobileMenu";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileSearchOptions } from "src/components/MobileSearchOptions";
import PropTypes, { func } from "prop-types";
import { useAtom } from "jotai/index";
import {
  cannedMessagesAtom,
  defaultCannedMessagesSearchValues,
  defaultTicketsSearchValues,
  departmentAtom,
  ticketsAtom,
} from "src/components/Searchbar";
import { compareTwoObjects } from "src/utils/compareTwoObjects";

const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide
      appear={false}
      direction="down"
      sx={{ display: { xs: "block", md: "none" }, background: "white" }}
      in={!trigger}
    >
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const MobileHeader = ({ setHeight }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [text, setText] = useState("");
  const [prevText, setPrevText] = useState("");
  const [from, setFrom] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [status, setStatus] = useState([]);
  const [after, setAfter] = useState("");
  const [before, setBefore] = useState("");
  const [department, setDepartment] = useState("");
  const [createdBy, setCreatedBy] = useState(null);
  const [version, setVersion] = useState("");
  const { operators } = useSelector((state) => state.operators);
  const { departments } = useSelector((state) => state.departments);
  const searchRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const scrollUp = useRef(true);
  const [ticketsValues, setTicketsValues] = useAtom(ticketsAtom);
  const [cannedMessagesValues, setCannedMessagesValues] = useAtom(cannedMessagesAtom);
  const [, setGlobalDepartment] = useAtom(departmentAtom);

  useEffect(() => {
    if (location.pathname.includes("cannedMessages")) {
      if (compareTwoObjects(cannedMessagesValues, defaultCannedMessagesSearchValues)) {
        setOpenOptions(false);
        return;
      }
      setOpenOptions(true);
    } else {
      if (
        compareTwoObjects(ticketsValues, defaultTicketsSearchValues, ["department", "isLoading"])
      ) {
        setOpenOptions(false);
        return;
      }
      setOpenOptions(true);
    }
  }, [cannedMessagesValues, ticketsValues, location]);

  useEffect(() => {
    if (!openOptions) {
      setHeight("100px");
      return;
    }
    setHeight("170px");
  }, [openOptions]);

  const getCannedMessagesParams = useCallback(() => {
    let newCreator;
    if (cannedMessagesValues.createdBy === 0) {
      newCreator = "administrator";
    } else {
      newCreator =
        operators &&
        operators.filter((op) => op.id === parseInt(cannedMessagesValues.createdBy))[0]?.email;
    }
    setAfter(cannedMessagesValues.after);
    setBefore(cannedMessagesValues.before);
    setText(cannedMessagesValues.text);
    setCreatedBy(newCreator);
  }, [cannedMessagesValues]);

  const getTicketsParams = useCallback(() => {
    const temp = location.pathname.split("/")[1];
    const dep = departments.filter(
      (Dep) => Dep.title.replaceAll(" ", "").toLowerCase() === temp.toLowerCase()
    )[0];
    if (dep === undefined && temp !== "All") {
      return;
    }
    setDepartment(temp);
    setGlobalDepartment(temp);

    let fromTemp = [];

    ticketsValues.from.forEach((element) => {
      fromTemp.push({ email: element });
    });

    setFrom(fromTemp);

    let newAssignee = [];

    ticketsValues.assignee?.forEach((element) => {
      newAssignee.push(operators && operators.filter((op) => op.id === parseInt(element))[0]);
    });
    setAssignee(newAssignee);
    setText(ticketsValues.text);
    setAfter(ticketsValues.after);
    setBefore(ticketsValues.before);
    setStatus(ticketsValues.status);
    setVersion(ticketsValues.version);
  }, [ticketsValues, location, departments, operators]);

  useEffect(() => {
    if (location.pathname.includes("cannedMessages")) {
      getCannedMessagesParams();
      return;
    }
    getTicketsParams();
  }, [location, getCannedMessagesParams, getTicketsParams]);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleFromChange = (newFrom) => {
    const fromTemp = newFrom.map((el) => {
      return { email: el.email };
    });
    setFrom(fromTemp);
    getFilteredTickets(fromTemp, "from");
  };

  const handleDepartmentChange = (newDepartment) => {
    setDepartment(newDepartment);
    getFilteredTickets(newDepartment, "department");
  };

  const handleAssigneeChange = (newAssignee) => {
    setAssignee(newAssignee);
    getFilteredTickets(newAssignee, "assignee");
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    getFilteredTickets(newStatus, "status");
  };

  const handleUpdatedFromChange = (newFrom) => {
    setAfter(newFrom);

    if (location.pathname.includes("cannedMessages")) {
      getFilteredCannedMessages(newFrom, "after");
      return;
    }
    getFilteredTickets(newFrom, "after");
  };

  const handleUpdatedToChange = (newTo) => {
    setBefore(newTo);

    if (location.pathname.includes("cannedMessages")) {
      getFilteredCannedMessages(newTo, "before");
      return;
    }
    getFilteredTickets(newTo, "before");
  };

  const handleCreatedByChange = (newCreator) => {
    setCreatedBy(newCreator);

    getFilteredCannedMessages(newCreator, "creator");
  };

  const handleVersionChange = (newVersion) => {
    setVersion(newVersion);
    getFilteredTickets(newVersion, "version");
  };

  const handleNavigation = useCallback(() => {
    if (y > window.scrollY + 5 && !scrollUp.current) {
      scrollUp.current = true;
    } else if (y < window.scrollY - 5 && scrollUp.current) {
      scrollUp.current = false;
    }
    if (window.scrollY === 0) {
      scrollUp.current = true;
    }
    setY(window.scrollY);
  }, [y]);

  useEffect(() => {
    window.onscroll = () => handleNavigation();
  }, [handleNavigation]);

  const handleClose = () => {
    setMenuOpen(false);
  };

  const getFilteredTickets = async (newParam, paramType) => {
    let fromParam = [];
    const fromTemp = paramType === "from" ? newParam : from;
    if (fromTemp.length > 0) {
      fromTemp.forEach((element) => {
        fromParam.push(element.email);
      });
    }

    let assigneeParam = [];
    const assigneeTemp = paramType === "assignee" ? newParam : assignee;
    if (assigneeTemp.length > 0) {
      assigneeTemp.forEach((element) => {
        assigneeParam.push(element.id);
      });
    }

    let statusTemp = paramType === "status" ? newParam : status;

    const afterTemp = paramType === "after" ? newParam : after;

    const beforeTemp = paramType === "before" ? newParam : before;

    const versionTemp = paramType === "version" ? newParam : version;

    if (status.length === 0 && text) {
      statusTemp = ["", "Any"];
    }
    let filterValues = {
      ...defaultTicketsSearchValues,
      status: statusTemp,
      from: fromParam,
      assignee: assigneeParam,
      after: afterTemp,
      before: beforeTemp,
      version: versionTemp,
      text: text.trim(),
      isLoading: false,
    };

    if (
      compareTwoObjects(filterValues, defaultTicketsSearchValues, ["isLoading", "status"]) &&
      statusTemp[1] === "Any"
    ) {
      filterValues = {
        ...filterValues,
        status: [],
      };
    }

    setTicketsValues(filterValues);

    const departmentTemp =
      paramType === "department"
        ? newParam
        : department === "" && text !== ""
          ? "All"
          : location.pathname.split("/")[1];
    setPrevText(text);
    navigate(`/${departmentTemp}`);
  };

  const getFilteredCannedMessages = (newParam, paramType) => {
    const createdByTemp = paramType === "creator" ? newParam : createdBy;

    const afterTemp = paramType === "after" ? newParam : after;

    const beforeTemp = paramType === "before" ? newParam : before;

    setCannedMessagesValues({
      createdBy: createdByTemp?.id,
      after: afterTemp,
      before: beforeTemp,
      text: text.trim(),
    });

    navigate("/cannedMessages");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchRef.current.blur();
    if (location.pathname.includes("cannedMessages")) {
      getFilteredCannedMessages();
      return;
    }
    getFilteredTickets();
  };

  const handleCloseOptions = () => {
    setFrom([]);
    setAssignee([]);
    setStatus([]);
    setText("");
    setPrevText("");
    setBefore("");
    setAfter("");
    setDepartment("");
    setCreatedBy(null);
    setOpenOptions(false);
    setHeight("100px");
    if (location.pathname.includes("cannedMessages")) {
      navigate("/cannedMessages");
      setCannedMessagesValues(defaultCannedMessagesSearchValues);
      return;
    }
    setTicketsValues(defaultTicketsSearchValues);
    const page = location.pathname.split("/")[1];
    navigate(`/${page}`);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar sx={{ background: "white" }} elevation={1}>
          <Box
            sx={{
              width: "100vw",
              height: 90,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <form style={{ width: "94%" }} onSubmit={handleSubmit}>
              <TextField
                inputRef={searchRef}
                value={text}
                onChange={handleChange}
                onFocus={() => {
                  setOpenOptions(true);
                  setHeight("170px");
                  setMenuOpen(false);
                }}
                autoComplete="off"
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  "& .MuiInputBase-root": {
                    height: 56,
                  },
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {text !== "" &&
                          (text !== prevText ? (
                            <IconButton name="search-button" type="submit">
                              <SearchIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              name="close-button"
                              type="button"
                              onClick={() => {
                                setText("");
                                setTimeout(() => {
                                  searchRef.current.focus();
                                }, 100);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          ))}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        {openOptions ? (
                          <IconButton name="back-button" type="button" onClick={handleCloseOptions}>
                            <KeyboardBackspaceRoundedIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            type="button"
                            name="menu-button"
                            onClick={() => {
                              setMenuOpen((prev) => !prev);
                            }}
                          >
                            {menuOpen ? <CloseIcon /> : <MenuIcon />}
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </form>
          </Box>
          <Collapse in={openOptions} timeout={{ enter: 150, exit: 50 }}>
            <MobileSearchOptions
              route={location.pathname}
              from={from}
              setFrom={handleFromChange}
              assignee={assignee}
              setAssignee={handleAssigneeChange}
              status={status}
              setStatus={handleStatusChange}
              after={after}
              setAfter={handleUpdatedFromChange}
              before={before}
              setBefore={handleUpdatedToChange}
              version={version}
              setVersion={handleVersionChange}
              department={department}
              setDepartment={handleDepartmentChange}
              createdBy={createdBy}
              setCreatedBy={handleCreatedByChange}
            />
          </Collapse>
        </AppBar>
      </HideOnScroll>
      <MobileMenu handleClose={handleClose} open={menuOpen} />
    </>
  );
};

MobileHeader.propTypes = {
  setHeight: func,
};

export { MobileHeader };
