import React, { useEffect, useMemo, useState } from "react";
import { ActiveFiltersContainer } from "src/components/ActiveFiltersContainer";
import { useAtom } from "jotai";
import { defaultTicketsSearchValues, ticketsAtom } from "src/components/Searchbar";
import { Button, Grid2, Typography } from "@mui/material";
import { StyledChip } from "src/components/StyledChip";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";

export const ActiveTicketsFilters = () => {
  const [filterValues, setFilterValues] = useAtom(ticketsAtom);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [assignee, setAssignee] = useState([]);
  const params = useParams();
  const { department } = params;
  const { operators } = useSelector((state) => state.operators);
  const navigate = useNavigate();

  const statuses = useMemo(() => {
    let statuses = [];
    filterValues.status.forEach((status) => {
      if (!status) {
        return;
      }
      statuses.push(status);
    });
    return statuses;
  }, [filterValues.status]);

  const showClearButton = useMemo(() => {
    return (
      statuses.length > 0 ||
      filterValues.before !== defaultTicketsSearchValues.before ||
      filterValues.after !== defaultTicketsSearchValues.after ||
      filterValues.from.length > 0 ||
      filterValues.assignee.length > 0 ||
      filterValues.version !== "any"
    );
  }, [filterValues, defaultTicketsSearchValues, statuses]);

  useEffect(() => {
    if (!filterValues.assignee || !operators) {
      return;
    }
    let assignees = [];
    filterValues.assignee.forEach((id) => {
      const assignee = operators.filter((op) => op.id === id)[0];
      assignees.push(assignee);
    });
    setAssignee(assignees);
  }, [filterValues.assignee, operators]);

  const totalCount = useMemo(() => {
    const { from, assignee } = filterValues;
    let totalCount = 0;
    if (from.length > 3) {
      totalCount += from.length - 3;
    }
    if (assignee.length > 3) {
      totalCount += assignee.length - 3;
    }
    return totalCount;
  }, [filterValues]);
  return (
    <ActiveFiltersContainer>
      {filterValues.from.length > 0 && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            From:{" "}
          </Typography>
          {filterValues.from.map((email, index) => {
            const item = (
              <StyledChip
                key={email}
                label={email}
                onDelete={() => {
                  setFilterValues((prev) => ({
                    ...prev,
                    from: prev.from.filter((item) => item !== email),
                  }));
                }}
              />
            );
            if (!showAllFilters) {
              if (index < 3) {
                return item;
              } else {
                return null;
              }
            } else {
              return item;
            }
          })}
        </>
      )}
      {department && department.toLowerCase() === "spam" && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Department:{" "}
          </Typography>
          <StyledChip label={department} onDelete={() => navigate("/All")} />
        </>
      )}
      {assignee.length > 0 && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Assignee:{" "}
          </Typography>
          {assignee.map((op, index) => {
            const item = (
              <StyledChip
                key={op.id}
                label={`${op.first_name} ${op.last_name}`}
                onDelete={() => {
                  setFilterValues((prev) => ({
                    ...prev,
                    assignee: prev.assignee.filter((item) => item !== op.id),
                  }));
                }}
              />
            );
            if (!showAllFilters) {
              if (index < 3) {
                return item;
              } else {
                return null;
              }
            } else {
              return item;
            }
          })}
        </>
      )}
      {filterValues.version && filterValues.version !== "any" && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Version:{" "}
          </Typography>
          <StyledChip
            label={`LV${filterValues.version}`}
            onDelete={() => {
              setFilterValues((prev) => ({
                ...prev,
                version: "any",
              }));
            }}
          />
        </>
      )}
      {filterValues.after && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Updated From:{" "}
          </Typography>
          <StyledChip
            label={format(new Date(filterValues.after), "dd MMM yyyy")}
            onDelete={() => {
              setFilterValues((prev) => ({
                ...prev,
                after: "",
              }));
            }}
          />
        </>
      )}
      {filterValues.before && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Updated To:{" "}
          </Typography>
          <StyledChip
            label={format(new Date(filterValues.before), "dd MMM yyy")}
            onDelete={() => {
              setFilterValues((prev) => ({
                ...prev,
                before: "",
              }));
            }}
          />
        </>
      )}
      {statuses.length > 0 && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Status:{" "}
          </Typography>
          {statuses.map((status) => (
            <StyledChip
              key={status}
              label={status}
              onDelete={() => {
                if (status === "Any") {
                  setFilterValues((prev) => ({
                    ...prev,
                    status: [],
                  }));
                } else {
                  setFilterValues((prev) => ({
                    ...prev,
                    status: prev.status.filter((item) => item !== status),
                  }));
                }
              }}
            />
          ))}
        </>
      )}
      {totalCount > 0 && (
        <Button onClick={() => setShowAllFilters((prev) => !prev)}>
          {showAllFilters ? "Hide" : `And ${totalCount} more`}
        </Button>
      )}
      {showClearButton && (
        <Grid2>
          <Button onClick={() => setFilterValues(defaultTicketsSearchValues)}>Clear</Button>
        </Grid2>
      )}
    </ActiveFiltersContainer>
  );
};
