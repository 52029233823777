import {
  Avatar,
  Badge,
  Box,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import logos from "src/images";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { stringIcon } from "src/utils/createIcon";
import { Notification } from "src/components/Notification";
import { useLocation } from "react-router-dom";
import { stringToColor } from "src/utils/stringToColor";
import { instances } from "src/helpers/instances";
import { func } from "prop-types";
import { defaultTicketsSearchValues, ticketsAtom } from "src/components/Searchbar";
import { useAtom } from "jotai";

const Sidebar = ({ changeWidth }) => {
  const [checked, setChecked] = useState(true);
  const [route, setRoute] = useState("");
  const [instance, setInstance] = useState("");
  const navigate = useNavigate();
  const { departments } = useSelector((state) => state.departments);
  const { all, count } = useSelector((state) => state.number);
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [, setValues] = useAtom(ticketsAtom);

  useEffect(() => {
    setInstance(instances[window.location.origin]);
  }, []);

  useEffect(() => {
    setRoute(location.pathname.split("/")[1]);
  }, [location]);

  const handleChange = () => {
    if (!checked) {
      changeWidth("300px");
      setChecked((prev) => !prev);
    } else {
      changeWidth("100px");
      setChecked((prev) => !prev);
    }
  };

  const changeRoute = async (newRoute) => {
    let page = newRoute;
    if (location.pathname.includes("filter")) {
      const params = location.search;
      page = `${page}/filter${params}`;
    }
    navigate(page);
  };

  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        position: "fixed",
        zIndex: 100,
        boxShadow: 3,
      }}
    >
      <Collapse
        orientation="horizontal"
        in={checked}
        collapsedSize={100}
        timeout={{ enter: 150, exit: 50 }}
        sx={{ overflow: "visible" }}
      >
        <Paper
          sx={{
            background: "#fcfcfc",
            overflow: "visible",
            width: checked ? "300px" : "100px",
            transition: checked ? "all 0.15s" : "all 0.05s",
          }}
          elevation={2}
        >
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              paddingTop: 2,
              zIndex: 5000,
              position: "relative",
              overflow: "visible",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: checked ? "100%" : "100px",
                position: "relative",
                overflow: "visible",
              }}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  height: "50px",
                  width: "260px",
                  marginLeft: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {checked ? (
                  <img
                    src={logos[instance]}
                    loading="lazy"
                    style={{
                      maxWidth: "260px",
                      maxHeight: "50px",
                      cursor: "pointer",
                    }}
                    alt="Logo"
                    onClick={() => {
                      setValues(defaultTicketsSearchValues);
                      if (user.departments?.length > 1) {
                        const dep = departments?.filter((dep) => dep.id === user.default_dep_id)[0];
                        if (!dep) {
                          navigate("/All");
                        } else {
                          navigate(`/${dep.title.replaceAll(" ", "")}`);
                        }
                      } else {
                        const dep = departments?.filter(
                          (dep) => dep.id === user.departments[0].department_id
                        )[0];
                        navigate(`/${dep.title.replaceAll(" ", "")}`);
                      }
                    }}
                  />
                ) : (
                  <img
                    src={logos[`${instance}Small`]}
                    loading="lazy"
                    style={{
                      maxWidth: "50px",
                      maxHeight: "50px",
                      marginLeft: "-10px",
                      cursor: "pointer",
                    }}
                    alt="Logo"
                    onClick={() => {
                      setValues(defaultTicketsSearchValues);
                      if (user.departments?.length > 1) {
                        const dep = departments?.filter((dep) => dep.id === user.default_dep_id)[0];
                        if (!dep) {
                          navigate("/All");
                        } else {
                          navigate(`/${dep.title.replaceAll(" ", "")}`);
                        }
                      } else {
                        const dep = departments?.filter(
                          (dep) => dep.id === user.departments[0].department_id
                        )[0];
                        navigate(`/${dep.title.replaceAll(" ", "")}`);
                      }
                    }}
                  />
                )}
              </Box>

              <IconButton
                name="collapse-button"
                sx={{
                  position: "absolute",
                  right: "-1.25rem",
                  background: "#fff",
                  border: "1px solid #ccc",
                  boxShadow: 1,
                  "&:hover": {
                    background: "#eee",
                    borderColor: "#ccc",
                  },
                }}
                onClick={handleChange}
              >
                <MenuSharpIcon fontSize="medium" sx={{ color: "black" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                marginTop: 2,
                width: "100%",
                overflowX: "auto",
                paddingBottom: 4,
              }}
            >
              {user.departments?.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    cursor: checked ? "pointer" : "",
                    background: checked && route === "All" ? "#ebf7f5" : undefined,
                    "&:hover": {
                      background: checked ? "#dff0ed" : undefined,
                    },
                  }}
                  onClick={() => checked && changeRoute("/All")}
                >
                  <Box
                    sx={{
                      height: "50px",
                      borderLeft: route === "All" ? "4px solid" : undefined,
                      borderColor: stringToColor("All"),
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "90%",
                      marginLeft: route === "All" ? 2.5 : 3,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={"All"} disableHoverListener={checked}>
                      <span>
                        <IconButton onClick={() => changeRoute("/All")} disabled={checked}>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <Notification>
                                {!location.pathname.includes("SPAM/filter") && all && all}
                              </Notification>
                            }
                          >
                            <Avatar {...stringIcon("All")} />
                          </Badge>
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography
                      variant="p"
                      sx={{
                        marginLeft: 2,
                        fontSize: 17,
                        display: checked ? "inline-block" : "none",
                        overflow: "hidden",
                      }}
                    >
                      All
                    </Typography>
                  </Box>
                </Box>
              )}
              {departments?.map((department) => {
                if (
                  !user.departments?.filter((dep) => dep.department_id === department.id)[0] &&
                  department.title !== "SPAM"
                ) {
                  return <div key={department.id}></div>;
                }
                const dep = count?.filter((number) => number.department_id === department.id)[0];
                return (
                  <Box
                    key={department.id}
                    sx={{
                      display:
                        department.title.replaceAll(" ", "").toLowerCase() !== "spam"
                          ? "flex"
                          : dep && (dep.openNumber > 0 || dep.onHoldNumber > 0)
                            ? "flex"
                            : "none",
                      alignItems: "center",
                      width: "100%",
                      cursor: checked ? "pointer" : "",
                      background:
                        checked && route === department.title.replace(/\s/g, "")
                          ? "#ebf7f5"
                          : undefined,
                      "&:hover": {
                        background: checked ? "#dff0ed" : undefined,
                      },
                    }}
                    onClick={() => checked && changeRoute(department.title.replace(/\s/g, ""))}
                  >
                    <Box
                      sx={{
                        height: "50px",
                        borderLeft:
                          route === department.title.replace(/\s/g, "") ? "4px solid" : undefined,
                        borderColor: stringToColor(department.title),
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "90%",
                        marginLeft: route === department.title.replace(/\s/g, "") ? 2.5 : 3,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title={department.title} disableHoverListener={checked}>
                        <span>
                          <IconButton
                            onClick={() => changeRoute(department.title.replace(/\s/g, ""))}
                            disabled={checked}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <Notification>
                                  {dep?.openNumber !== 0 ? dep?.openNumber : null}
                                </Notification>
                              }
                            >
                              <Avatar {...stringIcon(department.title)} />
                            </Badge>
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Typography
                        variant="p"
                        sx={{
                          marginLeft: 2,
                          fontSize: 17,
                          display: checked ? "inline-block" : "none",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {department.title}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Paper>
      </Collapse>
    </Box>
  );
};

Sidebar.propTypes = {
  changeWidth: func,
};

export { Sidebar };
