import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { changeTicketsStatusRequest } from "src/api/changeTicketsStatusRequest";

const TicketStatusChange = ({ status, id, halfWidth }) => {
  const [statuses] = useState([
    { id: 1, title: "Open" },
    { id: 2, title: "On-hold" },
    { id: 3, title: "Closed" },
  ]);

  const handleChange = async (e) => {
    const data = {
      ids: [id],
      status: e.target.value,
    };
    await changeTicketsStatusRequest(data);
  };

  return (
    <TextField
      select
      size="small"
      sx={{ width: halfWidth ? "49%" : "120px", marginLeft: 2, marginTop: 1 }}
      slotProps={{
        input: {
          inputProps: {
            sx: {
              fontSize: 14,
            },
          },
        },
      }}
      value={status === 0 ? 1 : status}
      onChange={handleChange}
      label="Status"
    >
      {statuses.map((option) => (
        <MenuItem key={option.id} value={option.id} sx={{ fontSize: 14 }}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

TicketStatusChange.propTypes = {
  status: PropTypes.number,
  id: PropTypes.number,
  halfWidth: PropTypes.bool,
};

export { TicketStatusChange };
