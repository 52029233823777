import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { operatorsRequest } from "src/api/operatorsRequest";
import { ticketNumberRequest } from "src/api/ticketNumberRequest";
import { Header } from "src/components/Header";
import { MobileHeader } from "src/components/MobileHeader";
import { Sidebar } from "src/components/Sidebar";
import { SocketContext } from "src/context/socket";
import { setNumber } from "src/redux/actions/numberActions";
import { setOperators } from "src/redux/actions/operatorsActions";
import { AppRoutes } from "src/Routes";
import { useLocation } from "react-router-dom";
import { generateJWT } from "./utils/generateJWT";
import SnackbarUtils from "./utils/SnackbarUtils";
import { useAtom } from "jotai";
import { defaultTicketsSearchValues, departmentAtom, ticketsAtom } from "src/components/Searchbar";
import { getParams } from "src/utils/getParams";
import { compareTwoObjects } from "src/utils/compareTwoObjects";

const Auth = () => {
  const [height, setHeight] = useState("100px");
  const [width, setWidth] = useState("300px");
  const [online, setOnline] = useState(true);
  const [numberTemp, setNumberTemp] = useState(-1);
  const { user } = useSelector((state) => state.user);
  const { departments } = useSelector((state) => state.departments);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const timeout = useRef();
  const errorTimeout = useRef();
  const firstTime = useRef(true);
  const oneTime = useRef(true);
  const getNumberRef = useRef(true);
  const [filterValues] = useAtom(ticketsAtom);
  const [department] = useAtom(departmentAtom);
  const [prevFilterValues, setPrevFilterValues] = useState(null);

  const connectToSocket = useCallback(async () => {
    socket.io.opts.auth.token = await generateJWT(user.id);
    socket.connect();
  }, [socket]);

  useEffect(() => {
    connectToSocket().then();
  }, [connectToSocket]);

  useEffect(() => {
    socket.on("connect_error", async (err) => {
      if (err.message === "not authorized") {
        socket.io.opts.auth.token = await generateJWT(user.id);
      }
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        socket.close();
        socket.connect();
      }, 3000);
    });

    socket.on("disconnect", (reason) => {
      if (reason !== "io client disconnect") {
        errorTimeout.current = setTimeout(() => {
          setOnline(false);
          SnackbarUtils.error("Not connected");
        }, 3002);
      }
    });

    socket.on("connect", async () => {
      clearTimeout(errorTimeout.current);
      if (!firstTime.current) {
        if (!online) {
          setOnline(true);
          SnackbarUtils.success("Your connection was restored");
        }
      }
      firstTime.current = false;
    });

    return () => {
      socket.off("connect_error");
      socket.off("disconnect");
      socket.off("connect");
    };
    //eslint-disable-next-line
  }, [online]);

  useEffect(() => {
    socket.on("numberChanged", (response) => {
      setNumberTemp(response);
    });
    return () => {
      socket.off("numberChanged");
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.includes("filter")) {
      return;
    }
    if (numberTemp === -1) {
      return;
    }
    let allowedDepartments = [];
    user.departments.forEach((element) => {
      allowedDepartments.push(element.department_id);
    });
    let all = 0;
    numberTemp.forEach((element) => {
      if (allowedDepartments.includes(element.department_id) || element.department_id === 1) {
        all += element.openNumber;
      }
    });
    dispatch(setNumber({ ticketsNumber: numberTemp, all }));
  }, [numberTemp, dispatch, user, location]);

  const getNumber = useCallback(async () => {
    if (
      prevFilterValues &&
      compareTwoObjects(filterValues, prevFilterValues, ["department", "isLoading"])
    ) {
      return;
    }
    if (!departments || departments.length === 0) {
      return;
    }

    let temp;
    let params = getParams(filterValues);
    if (department !== "All") {
      temp = departments.filter(
        (dep) => dep.title.replace(/\s/g, "").toLowerCase() === department.toLowerCase()
      )[0];
    }
    if (compareTwoObjects(filterValues, defaultTicketsSearchValues, ["department", "isLoading"])) {
      if (filterValues.isLoading) {
        return;
      }
      temp = null;
      params = {
        ...params,
        status: [0, 1],
      };
    }
    const response = await ticketNumberRequest(
      department.toLowerCase() === "spam" ? temp?.id : "all",
      new URLSearchParams(params)
    );
    if (response.status === 200) {
      getNumberRef.current = false;
      setPrevFilterValues(filterValues);
      let allowedDepartments = [];
      user.departments.forEach((element) => {
        allowedDepartments.push(element.department_id);
      });
      let all = 0;
      response.data.number.forEach((element) => {
        if (allowedDepartments.includes(element.department_id) || element.department_id === 1) {
          all += element.openNumber;
        }
      });
      dispatch(setNumber({ ticketsNumber: response.data.number, all }));
    }
  }, [dispatch, user, filterValues, prevFilterValues]);

  useEffect(() => {
    getNumber();
  }, [getNumber, filterValues]);

  const getOperators = useCallback(async () => {
    if (!oneTime.current) {
      return;
    }
    const response = await operatorsRequest();
    if (response.status === 200) {
      oneTime.current = false;
      let me = response.data.operators.filter((op) => op.id === user.id)[0];
      let temp = response.data.operators.filter((op) => op.id !== user.id);
      me = { ...me, first_name: "My", last_name: "Tickets" };
      dispatch(
        setOperators([
          me,
          {
            email: "unassigned",
            first_name: "Unassigned",
            last_name: "",
            id: 0,
          },
          ...temp,
        ])
      );
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    getOperators();
  }, [getOperators, user]);

  const changeWidth = (newWidth) => {
    setWidth(newWidth);
  };

  const changeHeight = useCallback((newHeight) => {
    setHeight(newHeight);
  }, []);

  return (
    <>
      <div style={{ opacity: online ? 1 : 0.7, pointerEvents: !online ? "none" : undefined }}>
        <MobileHeader setHeight={changeHeight} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Sidebar changeWidth={changeWidth} />
          <Box
            sx={{
              paddingLeft: { xs: 0, md: width },
              width: "100vw",
              transition: "all , 0.15s",
            }}
          >
            <Header sidebar={width} />
            <Box
              sx={{
                marginTop: { xs: height, md: "80px" },
                transition: "all .15s",
              }}
            >
              <AppRoutes sidebar={width !== "100px"} />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export { Auth };
