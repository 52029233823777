import React from "react";
import { io } from "socket.io-client";

export const socket =
  import.meta.env.VITE_ENV === "development" || !import.meta.env.VITE_ENV
    ? io(import.meta.env.VITE_BACKEND_URL, {
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        transports: ["websocket"],
        auth: {
          token: "",
        },
      })
    : io("/", {
        path: "/api/socket.io",
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        transports: ["websocket"],
        auth: {
          token: "",
        },
      });
export const SocketContext = React.createContext();
