import { Autocomplete, Button, Grid2, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CannedMessagesSearch = ({
  clearForm,
  setKeepOpen,
  submit,
  after,
  before,
  setAfter,
  setBefore,
  createdBy,
  setCreatedBy,
}) => {
  const { operators } = useSelector((state) => state.operators);
  const [options] = useState([
    { email: "administrator", id: 0, first_name: "Administrator", last_name: "" },
    ...operators,
  ]);
  const { user } = useSelector((state) => state.user);
  const [operatorsTemp, setOperators] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    let newOperator;
    newOperator = options.filter((op) => op.email === createdBy)[0];
    if (newOperator && newOperator.first_name === "My" && newOperator.last_name === "Tickets") {
      newOperator = { ...newOperator, first_name: user.first_name, last_name: user.last_name };
    }
    setOperators(newOperator);
  }, [createdBy, user, options]);

  const changeCreatedBy = (e, values) => {
    if (values) {
      setCreatedBy(values.email);
    } else {
      setCreatedBy("");
    }
  };

  const changeFromDate = (e) => {
    setAfter(e.target.value);
  };

  const changeToDate = (e) => {
    setBefore(e.target.value);
  };

  return (
    <form onSubmit={submit}>
      <Grid2 container sx={{ display: "flex", alignItems: "center" }}>
        <Grid2 size={{ xs: 12, md: 2 }} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Typography>Created By</Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 10 }} sx={{ marginTop: { xs: 0, md: 2 } }}>
          <Autocomplete
            sx={{ marginTop: 2 }}
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={operatorsTemp || null}
            onChange={changeCreatedBy}
            onFocus={() => {
              setFilter("");
              setKeepOpen(true);
            }}
            onBlur={() => {
              setKeepOpen(false);
            }}
            onInputChange={(e) => setFilter(e?.target?.value)}
            autoHighlight={true}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            filterOptions={(options) => {
              let newOptions = [];
              options.forEach((option) => {
                if (option.first_name === "Unassigned") {
                  return;
                }
                if (option.first_name === "My" && option.last_name === "Tickets") {
                  newOptions.push({
                    ...option,
                    first_name: user.first_name,
                    last_name: user.last_name,
                  });
                } else {
                  newOptions.push(option);
                }
              });
              if (!filter) {
                return newOptions;
              }
              newOptions = newOptions.filter((option) => {
                const name = `${option.first_name} ${option.last_name}`.toLowerCase();
                if (name.includes(filter.toLowerCase()) || option.email.includes(filter)) {
                  return option;
                } else return null;
              });
              return newOptions;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, md: 2 }} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Typography>Updated From</Typography>
        </Grid2>
        <Grid2 size={{ xs: 6, md: 4 }} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <TextField
            type="date"
            id="fromDate"
            value={after}
            onChange={changeFromDate}
            slotProps={{
              input: {
                inputProps: {
                  max: before ? before : format(new Date(), "yyyy-MM-dd"),
                },
              },
            }}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2
          size={{ xs: 6, md: 2 }}
          sx={{ marginTop: { xs: 1, md: 2 }, paddingLeft: { xs: 0, md: 2 } }}
        >
          <Typography>To</Typography>
        </Grid2>
        <Grid2 size={{ xs: 6, md: 4 }} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <TextField
            type="date"
            id="to"
            value={before}
            onChange={changeToDate}
            slotProps={{
              input: {
                inputProps: {
                  min: after ?? undefined,
                  max: format(new Date(), "yyyy-MM-dd"),
                },
              },
            }}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2
          size={{ xs: 12 }}
          sx={{ marginTop: 4, display: "flex", justifyContent: "flex-end", marginBottom: 2 }}
        >
          <Button
            sx={{ marginRight: 2, textTransform: "none" }}
            type="button"
            onClick={() => clearForm()}
          >
            Reset
          </Button>
          <Button sx={{ textTransform: "none" }} variant="contained" type="submit">
            Search
          </Button>
        </Grid2>
      </Grid2>
    </form>
  );
};

CannedMessagesSearch.propTypes = {
  clearForm: func,
  setKeepOpen: func,
  submit: func,
  after: string,
  before: string,
  setAfter: func,
  setBefore: func,
  createdBy: string,
  setCreatedBy: func,
};

export { CannedMessagesSearch };
