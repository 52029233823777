import axios from "axios";
import { getCookie } from "src/utils/getCookie";
import { instances } from "src/helpers/instances";
import { setTokens } from "src/utils/setTokens";
import SnackbarUtils from "src/utils/SnackbarUtils";
import { formatMessage } from "src/utils/formatMessage";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
  headers: {
    "X-Forwarded-Host": window.location.host,
  },
});

const instance = instances[window.location.origin];

axiosInstance.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${getCookie(`${instance}_access_key`)}`;
  config.headers["AuthorizationRefresh"] = `Bearer ${window.localStorage.getItem(
    `${instance}_refresh_key`
  )}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const headers = response.headers;
    setTokens(headers);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (window.location.hash === "" || window.location.hash !== "#/")
        return Promise.reject(error);
      window.location.href = "/";
      return;
    }
    if (
      error.response.status >= 400 &&
      error.response.status < 500 &&
      error.response.status !== 404
    ) {
      SnackbarUtils.error(formatMessage(error.response.data.message) || "Invalid Request");
    }
    if (error.response.status >= 500) {
      SnackbarUtils.error(error.response.statusText);
    }
    const headers = error.response.headers;
    setTokens(headers);
    return Promise.reject(error);
  }
);

export default axiosInstance;
