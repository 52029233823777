import * as jose from "jose";

const generateJWT = async (id) => {
  const alg = import.meta.env.VITE_SOCKET_ALG;
  const secret = new TextEncoder().encode(import.meta.env.VITE_SOCKET_SECRET);

  return await new jose.SignJWT({})
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer(id)
    .setExpirationTime("1m")
    .sign(secret);
};

export { generateJWT };
