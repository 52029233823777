import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addCannedMessageRequest } from "src/api/addCannedMessageRequest";

export const AddCannedMessageForm = () => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      title: e.target.title.value,
      content: e.target.content.value,
    };

    const response = await addCannedMessageRequest(data);
    if (response.status === 204) {
      navigate("/cannedMessages");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ background: "#f3f3f3", p: 1.5, borderRadius: 2 }}
    >
      <Typography variant="h5">Add Canned Message</Typography>
      <TextField
        size="small"
        label="Title"
        id="title"
        required
        autoFocus
        sx={{ width: "100%", marginTop: 2 }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          borderRadius: 3,
          px: active ? "14px" : "15px",
          py: active ? "6px" : "7px",
          mt: 2,
          border: active ? "2px solid #1976d2" : "1px solid #bdbdbd",
          "&:hover": {
            border: !active ? "1px solid black" : "",
          },
        }}
      >
        <TextField
          id="content"
          multiline
          required
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          variant="standard"
          placeholder="Message"
          sx={{
            width: "100%",
            background: "white",
          }}
          minRows={4}
          slotProps={{
            input: {
              disableUnderline: true,
            },
          }}
        />
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", mt: 2 }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "80px",
            borderRadius: 5,
            background: "#267aad",
            "&:hover": {
              background: "#1976d2",
            },
          }}
        >
          Add
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            marginLeft: 1,
            width: "80px",
            borderRadius: 5,
            background: "#808080",
            "&:hover": { background: "#686a6b" },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
