import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid2,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { editProfileRequest } from "src/api/editProfileRequest";
import { stringAvatar } from "src/utils/createBigAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import { validateEmail } from "src/utils/validateEmail";
import { setUser } from "src/redux/actions/seUser";
import { useEffect } from "react";
import SnackbarUtils from "src/utils/SnackbarUtils";

const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const { departments } = useSelector((state) => state.departments);
  const [notifications, setNotification] = useState(user.receive_notifications);
  const [departmentOptions, setDepartmnetOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      return;
    }
    let depIds = [];
    user.departments.forEach((dep) => {
      depIds.push(dep.department_id);
    });
    const deps = departments.filter((dep) => depIds.includes(dep.id));
    setDepartmnetOptions(deps);
    setSelectedDepartment(user.default_dep_id);
  }, [user, departments]);

  useEffect(() => {
    document.title = "Profile";
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(e.target.email.value.toLowerCase())) {
      SnackbarUtils.error("Email must be a valid email!");
      return;
    }

    const data = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      notifications: parseInt(notifications) === 1,
      defaultDepartment: selectedDepartment,
    };
    const response = await editProfileRequest(data);
    if (response.status === 200) {
      dispatch(setUser(response.data.user));
      SnackbarUtils.success("Profile updated successfully!");
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          background: "#fcfcfc",
          marginRight: { xs: 2, md: 4 },
          marginLeft: { xs: 2, md: 4 },
          marginTop: 1,
          marginBottom: 2,
          borderRadius: 4,
          padding: 3,
          minHeight: "65vh",
          width: { xs: "90%", md: "50%" },
          boxShadow: 3,
        }}
      >
        <Grid2 container>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{
              display: "flex",
              alignItems: { xs: "start", md: "end" },
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" sx={{ marginRight: 1, marginBottom: 2 }}>
              Profile
            </Typography>
            <Avatar
              {...stringAvatar(`${user.first_name} ${user.last_name}`)}
              src={returnGravatar(user.email)}
              alt={`${user.first_name} ${user.last_name}`}
            />
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 10 }}
            sx={{
              marginTop: { xs: "20px", md: "60px" },
              paddingLeft: { md: "20px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form onSubmit={handleSubmit}>
              <TextField
                label="First name"
                id="firstName"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    height: "50px",
                  },
                }}
                defaultValue={user.first_name}
                required
              />
              <TextField
                label="Last name"
                id="lastName"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  "& .MuiInputBase-root": {
                    height: "50px",
                  },
                }}
                defaultValue={user.last_name}
                required
              />
              <TextField
                label="Email"
                id="email"
                type="email"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  "& .MuiInputBase-root": {
                    height: "50px",
                  },
                }}
                defaultValue={user.email}
                required
              />
              <TextField
                label="Username"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  "& .MuiInputBase-root": {
                    height: "50px",
                  },
                }}
                defaultValue={user.username}
                disabled
              />
              <TextField
                label="Job Title"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  "& .MuiInputBase-root": {
                    height: "50px",
                  },
                }}
                defaultValue={user.job.title}
                disabled
              />
              {user.departments.length > 1 && (
                <TextField
                  label="Default Department"
                  select
                  sx={{
                    width: "100%",
                    marginTop: 2,
                    "& .MuiInputBase-root": {
                      height: "50px",
                    },
                  }}
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {departmentOptions.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <FormGroup
                sx={{ marginTop: 1, display: "flex", flexDirection: "row", alignItems: "center" }}
              >
                <FormLabel>Email Notifications: </FormLabel>
                <RadioGroup
                  value={notifications}
                  onChange={(e) => setNotification(e.target.value)}
                  row
                  sx={{ marginLeft: 3 }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormGroup>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ fontSize: 17, borderRadius: 5, width: "120px", background: "#267aad" }}
                >
                  Save
                </Button>
              </Box>
            </form>
          </Grid2>
        </Grid2>
      </Box>
    </div>
  );
};

export { Profile };
