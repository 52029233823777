import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { changeTicketsDepartmentRequest } from "src/api/changeTicketsDepartmentRequest";
import { bool, number } from "prop-types";

const TicketDepartmentChange = ({ department, id, margin, fullWidth }) => {
  const { departments } = useSelector((state) => state.departments);

  const handleChange = async (e) => {
    const data = {
      ids: [id],
      department: e.target.value,
    };

    await changeTicketsDepartmentRequest(data);
  };

  return (
    <TextField
      select
      size="small"
      sx={{ width: fullWidth ? "100%" : "180px", marginLeft: margin ? 2 : 0, marginTop: 1 }}
      slotProps={{
        input: {
          inputProps: {
            sx: {
              fontSize: 14,
            },
          },
        },
      }}
      value={department}
      onChange={handleChange}
      label="Department"
    >
      {departments.map((option) => (
        <MenuItem key={option.id} value={option.id} sx={{ fontSize: 14 }}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

TicketDepartmentChange.propTypes = {
  department: number,
  id: number,
  margin: bool,
  fullWidth: bool,
};

export { TicketDepartmentChange };
