import { Box, Button, IconButton, MenuItem, Modal, TextField, Typography } from "@mui/material";
import DriveFileMoveRoundedIcon from "@mui/icons-material/DriveFileMoveRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState } from "react";
import { style } from "src/styles/modalStyle";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeTicketsDepartmentRequest } from "src/api/changeTicketsDepartmentRequest";
import { array, bool, func } from "prop-types";

const ChangeDepartment = ({
  selected,
  close,
  small,
  setKeepOpen = () => {},
  enableIcon,
  closeBar,
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { departments } = useSelector((state) => state.departments);
  const currentDep =
    location.pathname.includes("All") || location.pathname.includes("ticket")
      ? ""
      : departments.filter(
          (dep) => dep.title.replaceAll(" ", "") === location.pathname.split("/")[1]
        )[0].id || null;
  const [department, setDepartment] = useState(currentDep || "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setKeepOpen(false);
    const data = {
      ids: selected,
      department,
    };
    if (department !== "") {
      await changeTicketsDepartmentRequest(data);
    }
    setOpen(false);
    if (enableIcon) {
      enableIcon();
    }
    if (closeBar) {
      closeBar();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: { md: small ? 0 : 2 },
          width: { xs: "100%", md: small ? "100%" : "fit-content" },
          justifyContent: small ? "start" : "center",
          py: small ? 0.5 : 1,
          px: small ? 2 : 1,
          borderRadius: !small ? 3 : undefined,
          transition: "background, 0.2s",
          "&:hover": { background: !small ? "#e6e6e6" : undefined },
        }}
        onClick={() => {
          setOpen(true);
          if (close) {
            close();
          }
        }}
      >
        {!small && <DriveFileMoveRoundedIcon />}
        <Typography sx={{ marginLeft: !small ? 1 : undefined, fontSize: 18 }}>
          {small ? "Department" : "DEPARTMENT"}
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          small && close();
          setKeepOpen(false);
          setOpen(false);
          if (enableIcon) {
            enableIcon();
          }
        }}
        onMouseOver={() => setKeepOpen(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" onSubmit={handleSubmit} sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ alignSelf: "center", fontSize: 28 }}
          >
            Change Department
          </Typography>
          <Typography variant="p">Select department</Typography>
          <TextField
            select
            label="Department"
            sx={{ marginTop: 2 }}
            value={department}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => setKeepOpen(false)}
            onChange={(e) => setDepartment(e.target.value)}
            slotProps={{
              select: {
                IconComponent: () => null,
              },
              input: {
                endAdornment: department && (
                  <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => setDepartment("")}>
                    <CloseRoundedIcon />
                  </IconButton>
                ),
              },
            }}
          >
            {departments &&
              departments
                .map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.title}
                  </MenuItem>
                ))
                .reverse()}
          </TextField>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 3 }}>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                small && close();
                setKeepOpen(false);
                setOpen(false);
                if (enableIcon) {
                  enableIcon();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={Boolean(currentDep === department)}
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                marginLeft: 2,
                background: "#267aad",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ChangeDepartment.propTypes = {
  selected: array,
  close: func,
  small: bool,
  setKeepOpen: func,
  enableIcon: func,
  closeBar: func,
};

export { ChangeDepartment };
