import { createApi } from "@reduxjs/toolkit/query/react";
import axiosInstance from "../service/axios/axiosInstance";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data }) => {
    try {
      const result = await axiosInstance({ url: baseUrl + url, method, data });
      return { data: result.data };
    } catch (err) {
      return { error: { status: err.response.status, data: err.response.data } };
    }
  };

export const aikideskApi = createApi({
  reducerPath: "aikideskApi",
  baseQuery: axiosBaseQuery({ baseUrl: import.meta.env.VITE_BACKEND_URL }),
  keepUnusedDataFor: 60 * 60,
  tagTypes: ["Ticket", "Tickets"],
  refetchOnReconnect: true,
  endpoints: () => ({}),
});
