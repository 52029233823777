import React from "react";
import PropTypes from "prop-types";
import { InputBase } from "src/components/InputBase";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "src/api/resetPasswordRequest";
import snackbarUtils from "../../utils/SnackbarUtils";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await resetPasswordRequest(e.target.email.value);
    if (response.status === 204) {
      snackbarUtils.success("You will receive an email with your new password soon.");
      navigate("/");
    }
  };

  return (
    <InputBase>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: "60px",
          marginLeft: "35px",
          marginRight: "35px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "40px",
        }}
      >
        <Typography variant="h5">Forgot password</Typography>
        <Typography variant="p">Please enter your Help Desk email address below.</Typography>
        <TextField
          slotProps={{
            input: {
              sx: { color: "black" },
            },
          }}
          sx={{ marginTop: 3, "& ::placeholder": { opacity: 0.9 } }}
          required
          id="email"
          label="Email"
          placeholder="Enter your email"
          type="email"
        />
        <Button
          variant="contained"
          type="submit"
          sx={{ width: "100%", marginTop: 5, height: 50, fontSize: 18, background: "#267aad" }}
        >
          Submit
        </Button>
        <Typography
          sx={{
            textTransform: "none",
            color: "black",
            fontSize: 17,
            marginTop: 2,
            alignSelf: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          Cancel
        </Typography>
      </Box>
    </InputBase>
  );
};

ForgotPassword.propTypes = {
  setMessage: PropTypes.func,
};
