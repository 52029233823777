import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { style } from "src/styles/modalStyle";
import { useSelector } from "react-redux";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { format } from "date-fns";
import { addReminderRequest } from "src/api/addReminderRequest";
import { removeReminderRequest } from "src/api/removeReminderRequest";
import { editReminderRequest } from "src/api/editReminderRequest";
import { array, number } from "prop-types";
import snackbarUtils from "../../utils/SnackbarUtils";

const Reminders = ({ reminders, ticket_id }) => {
  const [open, setOpen] = useState(false);
  const [openAddNewReminder, setOpenAddNewReminder] = useState(false);
  const [openEditReminder, setOpenEditReminder] = useState(false);
  const [date, setDate] = useState(new Date(new Date(new Date()).setHours(8)).setMinutes(0));
  const [editDate, setEditDate] = useState(new Date().toString());
  const [editMessage, setEditMessage] = useState("");
  const [editInternal, setEditInternal] = useState(0);
  const [id, setId] = useState(-1);
  const [hours] = useState([0, ...Array.from({ length: 23 }, (v, k) => k + 1)]);
  const [minutes] = useState([0, ...Array.from({ length: 59 }, (v, k) => k + 1)]);
  const [selected, setSelected] = useState([]);
  const [length, setLength] = useState(0);
  const [title, setTitle] = useState("Reminders");
  const [hover, setHover] = useState(-1);
  const { user } = useSelector((state) => state.user);
  const { operators } = useSelector((state) => state.operators);

  const deleteReminder = async () => {
    const data = {
      ticket_id: reminders[0].ticket_id,
      ids: selected,
    };
    setSelected([]);
    setLength(0);
    await removeReminderRequest(data);
  };

  const handleSelectAll = () => {
    let temp = [];
    if (reminders.length === selected.length) {
      setSelected([]);
      setLength(0);
      return;
    }
    reminders.forEach((element) => {
      temp.push(element.id);
    });
    setLength(temp.length);
    setSelected(temp);
  };

  const handleSelect = (id) => {
    let temp = selected;
    if (temp.includes(id)) {
      temp = temp.filter((Id) => Id !== id);
    } else {
      temp.push(id);
    }
    setLength(temp.length);
    setSelected(temp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (new Date(date) <= new Date()) {
      snackbarUtils.error("Please select a future time");
      return;
    }
    const data = {
      date: date / 1000,
      ticket_id,
      analyst_only: e.target.operatorsOnly.checked ? 1 : 0,
      message: e.target.message.value,
    };
    await addReminderRequest(data);
    e.target.reset();
    setOpenAddNewReminder(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (new Date(editDate) <= new Date()) {
      snackbarUtils.error("Please select a future time");
      return;
    }
    const data = {
      date: editDate / 1000,
      message: editMessage,
      analyst_only: editInternal ? 1 : 0,
      completed: new Date(editDate) < new Date() ? 1 : 0,
      id: id,
      ticket_id,
    };
    setId(-1);
    await editReminderRequest(data);
    setOpenEditReminder(false);
  };

  const handleAddReminderClick = () => {
    setOpenEditReminder(false);
    setId(-1);
    setOpenAddNewReminder(true);
    setSelected([]);
    setLength(0);
    setTitle("Add Reminder");
  };

  const handleEditReminderClick = (reminder) => {
    setOpenAddNewReminder(false);
    setSelected([]);
    setLength(0);
    setTitle("Edit Reminder");
    setEditDate(new Date(reminder.ts_scheduled * 1000));
    setEditMessage(reminder.message || "");
    setEditInternal(reminder.analyst_only);
    setId(reminder.id);

    setOpenEditReminder(true);
  };

  const pad = (d) => {
    return d.length < 2 ? "0" + d : d;
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none", fontSize: 14, p: 0, px: 1, minWidth: 0, fontWeight: "normal" }}
        onClick={() => setOpen(true)}
      >
        {reminders.length > 0 &&
        new Date(reminders[reminders.length - 1].ts_scheduled * 1000) >= new Date()
          ? format(
              new Date(
                reminders
                  .sort((a, b) => {
                    return a.ts_scheduled - b.ts_scheduled;
                  })
                  .filter((reminder) => new Date(reminder.ts_scheduled * 1000) >= new Date())[0]
                  ?.ts_scheduled * 1000
              ),
              "dd MMM yyyy HH:mm"
            )
          : "None"}
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, background: "white", width: "550px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              {length > 0 ? `Delete ${length > 1 ? "reminders" : "reminder"}` : title}
            </Typography>
            <Tooltip title="Close">
              <IconButton sx={{ marginRight: -2, marginTop: -1 }} onClick={() => setOpen(false)}>
                <CloseRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          <TableContainer sx={{ display: reminders.length > 0 ? "block" : "none" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={Boolean(length === reminders.length && length !== 0)}
                      onChange={handleSelectAll}
                      sx={{
                        display: openAddNewReminder || openEditReminder ? "none" : "flex",
                      }}
                    />
                  </TableCell>
                  {length > 0 ? (
                    <TableCell colSpan={3}>
                      <Button
                        sx={{
                          textTransform: "none",
                          px: 2,
                          py: 1,
                          marginLeft: -2,
                          marginBottom: -1,
                          marginTop: -1,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                        onClick={deleteReminder}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>
                        <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: "bold" }}>Created By</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: "bold" }}>Internal</Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {reminders
                  .sort((a, b) => {
                    return a.ts_scheduled - b.ts_scheduled;
                  })
                  .map((reminder, index) => (
                    <TableRow
                      key={reminder.id}
                      sx={{
                        background:
                          hover === index && reminder.id !== id
                            ? "#fffae6"
                            : reminder.id === id
                              ? "#2196f3"
                              : new Date(reminder.ts_scheduled * 1000) < new Date()
                                ? "#ebebeb"
                                : undefined,
                        transition: "all .3s",
                      }}
                    >
                      <TableCell sx={{ width: "30px" }}>
                        <Checkbox
                          checked={selected.includes(reminder.id)}
                          onChange={() => handleSelect(reminder.id)}
                          sx={{
                            display: openAddNewReminder || openEditReminder ? "none" : "flex",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ width: "140px", cursor: reminder.id !== id ? "pointer" : "" }}
                        onMouseOver={() => {
                          reminder.id !== id && setHover(index);
                        }}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => {
                          reminder.id !== id && handleEditReminderClick(reminder);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              reminder.id === id
                                ? "white"
                                : new Date(reminder.ts_scheduled * 1000) < new Date()
                                  ? "#525252"
                                  : "black",
                          }}
                        >
                          {format(new Date(reminder.ts_scheduled * 1000), "dd MMM yyyy HH:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: reminder.id !== id ? "pointer" : "" }}
                        onMouseOver={() => {
                          reminder.id !== id && setHover(index);
                        }}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => {
                          reminder.id !== id && handleEditReminderClick(reminder);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              reminder.id === id
                                ? "white"
                                : new Date(reminder.ts_scheduled * 1000) < new Date()
                                  ? "#525252"
                                  : "black",
                          }}
                        >
                          {reminder.analyst_id === user?.id
                            ? `${user.first_name} ${user.last_name}`
                            : `${
                                operators?.filter((op) => op.id === reminder.analyst_id)[0]
                                  .first_name
                              } ${
                                operators?.filter((op) => op.id === reminder.analyst_id)[0]
                                  .last_name
                              }`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "70px", cursor: reminder.id !== id ? "pointer" : "" }}
                        onMouseOver={() => {
                          reminder.id !== id && setHover(index);
                        }}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => {
                          reminder.id !== id && handleEditReminderClick(reminder);
                        }}
                      >
                        {reminder.analyst_only && (
                          <SupportAgentIcon
                            sx={{
                              color:
                                reminder.id === id
                                  ? "white"
                                  : new Date(reminder.ts_scheduled * 1000) < new Date()
                                    ? "#525252"
                                    : "black",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: "100%",
              display: openAddNewReminder ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ textTransform: "none", fontSize: 16, marginTop: 2 }}
              onClick={handleAddReminderClick}
            >
              + Add Reminder
            </Button>
          </Box>
          {openAddNewReminder && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 2,
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  id="fromDate"
                  size="small"
                  type="date"
                  sx={{ width: "150px" }}
                  slotProps={{
                    input: {
                      inputProps: { min: format(new Date(), "yyyy-MM-dd") },
                      sx: { paddingRight: { xs: "10px", md: 0 } },
                    },
                  }}
                  value={format(date, "yyyy-MM-dd")}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    const tempDate = new Date(date);
                    tempDate.setDate(new Date(e.target.value).getDate());
                    tempDate.setFullYear(new Date(e.target.value).getFullYear());
                    tempDate.setMonth(new Date(e.target.value).getMonth());
                    setDate(tempDate);
                  }}
                  required
                />
                <Box sx={{ display: "flex" }}>
                  <Autocomplete
                    options={hours}
                    getOptionLabel={(option) => pad(option.toString())}
                    disableClearable
                    id="fromHours"
                    size="small"
                    getOptionDisabled={(option) =>
                      format(new Date(date), "yyyy-MM-dd") <= format(new Date(), "yyyy-MM-dd") &&
                      option < new Date().getHours()
                    }
                    value={format(date, "HH")}
                    onChange={(_, values) => setDate(new Date(date).setHours(values))}
                    sx={{ width: 80 }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                  <Autocomplete
                    options={minutes}
                    getOptionLabel={(option) => pad(option.toString())}
                    disableClearable
                    id="fromMinutes"
                    size="small"
                    getOptionDisabled={(option) =>
                      new Date(date) <= new Date() && option <= new Date().getMinutes()
                    }
                    value={format(date, "mm")}
                    onChange={(_, values) => setDate(new Date(date).setMinutes(values))}
                    sx={{ width: 80, marginLeft: 1 }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </Box>
              </Box>
              <TextField multiline sx={{ marginTop: 2 }} id="message" placeholder="Message" />
              <Box sx={{ display: "flex", marginTop: 2, justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={<Checkbox id="operatorsOnly" />}
                    label="Internal"
                    sx={{ marginLeft: 0.2 }}
                  />
                </Box>
                <Box>
                  <Tooltip title="Add">
                    <IconButton type="submit">
                      <CheckRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Cancel"
                    onClick={() => {
                      setOpenAddNewReminder(false);
                      setTitle("Reminders");
                    }}
                  >
                    <IconButton>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          )}
          {openEditReminder && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 2,
              }}
              component="form"
              id="editReminderFrom"
              onSubmit={handleUpdate}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  id="fromDate"
                  size="small"
                  type="date"
                  sx={{ width: "150px" }}
                  slotProps={{
                    input: {
                      inputProps: { min: format(new Date(), "yyyy-MM-dd") },
                      sx: { paddingRight: { xs: "10px", md: 0 } },
                    },
                  }}
                  value={format(editDate, "yyyy-MM-dd")}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    const tempDate = new Date(editDate);
                    tempDate.setDate(new Date(e.target.value).getDate());
                    tempDate.setFullYear(new Date(e.target.value).getFullYear());
                    tempDate.setMonth(new Date(e.target.value).getMonth());
                    setEditDate(tempDate);
                  }}
                  required
                />
                <Box sx={{ display: "flex" }}>
                  <Autocomplete
                    options={hours}
                    getOptionLabel={(option) => pad(option.toString())}
                    disableClearable
                    id="fromHours"
                    size="small"
                    getOptionDisabled={(option) =>
                      format(new Date(editDate), "yyyy-MM-dd") <=
                        format(new Date(), "yyyy-MM-dd") && option < new Date().getHours()
                    }
                    value={format(editDate, "HH")}
                    onChange={(_, values) => setEditDate(new Date(editDate).setHours(values))}
                    sx={{ width: 80 }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                  <Autocomplete
                    options={minutes}
                    getOptionLabel={(option) => pad(option.toString())}
                    disableClearable
                    id="fromMinutes"
                    size="small"
                    getOptionDisabled={(option) =>
                      new Date(editDate) <= new Date() && option <= new Date().getMinutes()
                    }
                    value={format(editDate, "mm")}
                    onChange={(_, values) => setEditDate(new Date(editDate).setMinutes(values))}
                    sx={{ width: 80, marginLeft: 1 }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </Box>
              </Box>
              <TextField
                multiline
                value={editMessage}
                onChange={(e) => {
                  setEditMessage(e.target.value);
                }}
                sx={{ marginTop: 2 }}
                id="message"
                placeholder="Message"
              />
              <Box sx={{ display: "flex", marginTop: 2, justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(editInternal)}
                        onChange={(e) => {
                          setEditInternal(e.target.checked ? 1 : 0);
                        }}
                      />
                    }
                    label="Internal"
                    sx={{ marginLeft: 0.2 }}
                  />
                </Box>
                <Box>
                  <Tooltip title="Update">
                    <IconButton type="submit">
                      <CheckRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Cancel"
                    onClick={() => {
                      setOpenEditReminder(false);
                      setId(-1);
                      setTitle("Reminders");
                    }}
                  >
                    <IconButton>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

Reminders.propTypes = {
  reminders: array,
  ticket_id: number,
};

export { Reminders };
