import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { replyToTicketRequest } from "src/api/replyToTicketRequest";
import { AttachmentUploadCard } from "src/components/AttachmentUploadCard";
import { allCannedMessagesRequest } from "src/api/allCannedMessagesRequest";
import { func, number, object, string } from "prop-types";
import { addFiles } from "src/utils/addFiles";
import snackbarUtils from "src/utils/SnackbarUtils";

const ReplyToTicket = ({
  setOpen,
  ticket,
  analyst_only,
  openInternalMessage,
  openReply,
  message,
  setMessage,
  messageRef,
}) => {
  const [openCannedMessages, setOpenCannedMessages] = useState(false);
  const [cannedMessagesTemp, setCannedMessagesTemp] = useState([]);
  const [cannedMessage, setCannedMessage] = useState(null);
  const [filter, setFilter] = useState("");
  const [files, setFiles] = useState([]);
  const { preferences } = useSelector((state) => state.preferences);
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [active, setActive] = useState(false);
  const fileRef = useRef(null);

  const cannedMessagesOpen = async () => {
    if (cannedMessagesTemp.length > 0) {
      setFilter("");
      setOpenCannedMessages((prev) => !prev);
      return;
    }
    const response = await allCannedMessagesRequest(0, 1000);
    if (response.status === 200) {
      setCannedMessagesTemp(response.data.canned_messages);
    }
    setFilter("");
    setOpenCannedMessages((prev) => !prev);
  };

  const handleInputChange = async (e) => {
    if (!e || !e.target) {
      return;
    }
    setFilter(e.target.value);
  };

  const handleCannedMessageChange = (e, values) => {
    setCannedMessage(values);
    if (!values) {
      return;
    }
    setMessage(values.content);
    setOpenCannedMessages(false);
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFilesChange = (e) => {
    try {
      const newFiles = addFiles(preferences, files, e.target.files);
      setFiles(newFiles);
    } catch (err) {
      snackbarUtils.error(err.message);
    }
  };

  const removeFile = (id) => {
    let temp = files;

    temp = temp.filter((file) => file.id !== id);

    setFiles(temp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const time = (Math.abs(parseInt(hours)) * 60 + Math.abs(parseInt(minutes))) * 60;
    let temp = [];
    files.forEach((file) => {
      temp.push(file.file);
    });
    const data = new FormData();
    temp.forEach((element, index) => {
      data.append("file" + index, element);
    });
    data.append("message", message);
    data.append("subject", ticket.subject);
    data.append("ticket_id", ticket.id);
    data.append("analyst_only", analyst_only);
    data.append("ticket_number", ticket.ticket_number);
    data.append("total_time", time ? time : null);
    data.append("status", e.target.close.checked ? 3 : analyst_only ? ticket.status_id : 2);
    const response = await replyToTicketRequest(data);
    if (response.status === 200) {
      setOpen();
      setMessage("");
    }
  };

  return (
    <Box
      sx={{
        background: analyst_only ? "#dbca7f" : "#f7f7f7",
        p: 1,
        borderRadius: 3,
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: ticket.status_id !== 3 ? "flex" : "none",
          justifyContent: "end",
        }}
      >
        <Button
          variant={analyst_only === 0 ? "outlined" : "contained"}
          onClick={openInternalMessage}
          sx={{
            textTransform: "none",
            width: "180px",
            marginRight: 1,
            borderRadius: 4,
            color: "#9e8410",
            borderColor: "#9e8410",
            "&:hover": {
              borderColor: "#5c4c08",
            },
            display: "flex",
            alignItems: "center",
          }}
          disabled={analyst_only === 1}
        >
          <MailLockRoundedIcon />
          <Typography sx={{ marginLeft: 0.5, marginTop: 0.2 }} variant="p">
            Internal Message
          </Typography>
        </Button>
        <Button
          variant={analyst_only === 1 ? "outlined" : "contained"}
          onClick={openReply}
          sx={{
            textTransform: "none",
            width: "100px",
            marginRight: 1,
            borderRadius: 4,
            color: "#5c5c5c",
            borderColor: "#5c5c5c",
            "&:hover": {
              borderColor: "#474747",
            },
            display: "flex",
            alignItems: "center",
          }}
          disabled={analyst_only === 0}
        >
          <ReplyRoundedIcon />
          <Typography sx={{ marginLeft: 0.5, marginTop: 0.2 }} variant="p">
            Reply
          </Typography>
        </Button>
        <Tooltip title="Cancel">
          <IconButton onClick={setOpen}>
            <CloseRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "white",
            borderRadius: 3,
            px: active ? "14px" : "15px",
            py: active ? "6px" : "7px",
            mt: 1,
            border: active ? "2px solid #1976d2" : "1px solid #bdbdbd",
            "&:hover": {
              border: !active ? "1px solid black" : "",
            },
          }}
        >
          <TextField
            id="message"
            multiline
            value={message}
            inputRef={messageRef}
            onChange={handleChange}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            variant="standard"
            placeholder={analyst_only ? "Internal Message" : "Message"}
            sx={{
              width: "100%",
              background: "white",
              borderRadius: 2,
            }}
            minRows={4}
            slotProps={{
              input: {
                disableUnderline: true,
              },
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              flexWrap: "wrap",
              marginLeft: { md: -2 },
            }}
          >
            {files.length > 0 &&
              files.map((file) => (
                <AttachmentUploadCard
                  key={file.id}
                  file={file}
                  index={files.indexOf(file)}
                  removeFile={removeFile}
                />
              ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            alignItems: "center",
            marginTop: 2,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Typography>Time Log: </Typography>
          <TextField
            id="hours"
            slotProps={{
              input: {
                inputProps: { min: 0 },
              },
            }}
            sx={{
              marginLeft: 1,
              width: "50px",
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                },
              },
            }}
            size="small"
            value={hours}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setHours(e.target.value);
              }
            }}
          />
          <Typography sx={{ marginLeft: 1 }}>h</Typography>
          <TextField
            id="minutes"
            slotProps={{
              input: {
                inputProps: { min: 0 },
              },
            }}
            sx={{
              marginLeft: 1,
              width: "50px",
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                },
              },
            }}
            size="small"
            value={minutes}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setMinutes(e.target.value);
              }
            }}
          />
          <Typography sx={{ marginLeft: 1 }}>m</Typography>
        </Box>
        {openCannedMessages && (
          <Autocomplete
            sx={{
              display: { xs: "inline-block", md: "none" },
              width: "100%",
              background: "#fcfcfc",
              marginTop: 2,
            }}
            id="cannedMessage"
            disablePortal
            options={cannedMessagesTemp}
            getOptionLabel={(option) => option.title}
            value={cannedMessage || null}
            filterOptions={(options) => {
              if (!filter) {
                return options;
              }
              return options.filter(
                (option) => option.title.includes(filter) || option.content.includes(filter)
              );
            }}
            filterSelectedOptions
            autoHighlight={true}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleCannedMessageChange}
            onInputChange={handleInputChange}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.title}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Canned Message" />}
          />
        )}
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1, justifyContent: "end" }}>
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
            <Typography>Time Log: </Typography>
            <TextField
              id="hours"
              slotProps={{
                input: {
                  inputProps: { min: 0 },
                },
              }}
              sx={{
                marginLeft: 1,
                width: "50px",
              }}
              size="small"
              value={hours}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setHours(e.target.value);
                }
              }}
            />
            <Typography sx={{ marginLeft: 1 }}>h</Typography>
            <TextField
              id="minutes"
              slotProps={{
                input: {
                  inputProps: { min: 0 },
                },
              }}
              sx={{
                marginLeft: 1,
                width: "50px",
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                  },
                },
              }}
              size="small"
              value={minutes}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setMinutes(e.target.value);
                }
              }}
            />
            <Typography sx={{ marginLeft: 1 }}>m</Typography>
          </Box>
          <Box sx={{ marginLeft: { md: 1 }, display: "flex", alignItems: "center" }}>
            <FormControlLabel control={<Checkbox id="close" />} label="Close Ticket" />
          </Box>
          <Tooltip title="Select Canned Message">
            <IconButton onClick={cannedMessagesOpen}>
              <PostAddIcon />
            </IconButton>
          </Tooltip>
          {openCannedMessages && (
            <Autocomplete
              sx={{
                display: { xs: "none", md: "inline-block" },
                width: "250px",
                background: "none",
              }}
              id="cannedMessage"
              disablePortal
              size="small"
              options={cannedMessagesTemp}
              getOptionLabel={(option) => option.title}
              value={cannedMessage || null}
              filterOptions={(options) => {
                if (!filter) {
                  return options;
                }
                return options.filter(
                  (option) =>
                    option.title.toLowerCase().includes(filter.toLowerCase()) ||
                    option.content.toLowerCase().includes(filter.toLowerCase())
                );
              }}
              autoHighlight={true}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleCannedMessageChange}
              onInputChange={handleInputChange}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.title}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label="Canned Message" />}
            />
          )}
          <input
            type="file"
            multiple
            onChange={handleFilesChange}
            ref={fileRef}
            style={{ display: "none" }}
          />
          <Tooltip title="Attach a File">
            <IconButton onClick={() => fileRef.current.click()}>
              <AttachFileIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            type="submit"
            disabled={Boolean(!message)}
            sx={{
              borderRadius: 4,
              background: "#3d3d3d",
              marginTop: 0.5,
              marginLeft: 1,
              "&:hover": {
                background: "#262626",
              },
            }}
          >
            Post
          </Button>
        </Box>
      </form>
    </Box>
  );
};

ReplyToTicket.propTypes = {
  setOpen: func,
  ticket: object,
  analyst_only: number,
  openInternalMessage: func,
  openReply: func,
  message: string,
  setMessage: func,
  messageRef: object,
};

export { ReplyToTicket };
