import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useState } from "react";
import { style } from "src/styles/modalStyle";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { removeTimeLogsRequest } from "src/api/removeTimeLogsRequest";
import { addTimeLogRequest } from "src/api/addTimeLogRequest";
import { array, number } from "prop-types";

const EditTimeLog = ({ timeLogs, ticket_id, analyst_id, myTime }) => {
  const [open, setOpen] = useState(false);
  const [openCreateNewLog, setOpenCreateNewLog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [length, setLength] = useState(0);
  const [hours] = useState([0, ...Array.from({ length: 23 }, (v, k) => k + 1)]);
  const [minutes] = useState([0, ...Array.from({ length: 59 }, (v, k) => k + 1)]);
  const [addHours, setAddHours] = useState("00");
  const [addMinutes, setAddMinutes] = useState("00");

  const deleteLogs = async () => {
    const data = {
      ticket_id: timeLogs[0].ticket_id,
      ids: selected,
    };
    setSelected([]);
    setLength(0);
    await removeTimeLogsRequest(data);
  };

  const handleSelectAll = () => {
    let temp = [];
    if (timeLogs.length === selected.length) {
      setSelected([]);
      setLength(0);
      return;
    }
    timeLogs.forEach((element) => {
      temp.push(element.id);
    });
    setLength(temp.length);
    setSelected(temp);
  };

  const handleSelect = (id) => {
    let temp = selected;
    if (temp.includes(id)) {
      temp = temp.filter((Id) => Id !== id);
    } else {
      temp.push(id);
    }
    setLength(temp.length);
    setSelected(temp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newDate = `${e.target.fromDate.value} ${e.target.fromHours.value}:${e.target.fromMinutes.value}`;
    const date = new Date(newDate) / 1000;
    const time = (Math.abs(parseInt(addHours)) * 60 + Math.abs(parseInt(addMinutes))) * 60;
    const data = {
      date,
      time,
      ticket_id,
      analyst_id,
    };
    e.target.reset();
    setOpenCreateNewLog(false);
    await addTimeLogRequest(data);
  };

  const pad = (d) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none", fontSize: 14, p: 0, px: 1, fontWeight: "normal" }}
        onClick={() => setOpen(true)}
      >
        {Math.floor(myTime / 3600) < 10
          ? `0${Math.floor(myTime / 3600)}`
          : Math.floor(myTime / 3600)}{" "}
        h{" "}
        {Math.floor((myTime % 3600) / 60) < 10
          ? `0${Math.floor((myTime % 3600) / 60)}`
          : Math.floor((myTime % 3600) / 60)}{" "}
        m
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, background: "white", minWidth: { xs: "90vw", md: "540px" } }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">My Time Log</Typography>
            <Tooltip title="Close">
              <IconButton sx={{ marginRight: -2, marginTop: -1 }} onClick={() => setOpen(false)}>
                <CloseRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          <TableContainer sx={{ display: timeLogs.length > 0 ? "block" : "none" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={Boolean(length === timeLogs.length && length !== 0)}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {length > 0 ? (
                    <TableCell colSpan={2}>
                      <Button
                        sx={{
                          textTransform: "none",
                          px: 2,
                          py: 1,
                          marginLeft: -2,
                          marginBottom: -1,
                          marginTop: -1,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                        onClick={deleteLogs}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>
                        <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: "bold" }}>Time</Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {timeLogs
                  .sort((a, b) => {
                    return a.ts_date - b.ts_date;
                  })
                  .map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>
                        <Checkbox
                          checked={selected.includes(log.id)}
                          onChange={() => handleSelect(log.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {format(new Date(log.ts_date * 1000), "dd MMM yyyy HH:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {Math.floor(log.total_time / 3600) < 10
                            ? `0${Math.floor(log.total_time / 3600)}`
                            : Math.floor(log.total_time / 3600)}{" "}
                          h{" "}
                          {Math.floor((log.total_time % 3600) / 60) < 10
                            ? `0${Math.floor((log.total_time % 3600) / 60)}`
                            : Math.floor((log.total_time % 3600) / 60)}{" "}
                          m{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: "100%",
              display: openCreateNewLog ? "none" : "flex",
              justifyContent: "center",
              marginTop: 1,
            }}
          >
            <Button
              sx={{ textTransform: "none", fontSize: 16, marginTop: 2 }}
              onClick={() => setOpenCreateNewLog(true)}
            >
              + Add Time Log
            </Button>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: openCreateNewLog ? "block" : "none", marginTop: 2 }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
              <TextField
                id="fromDate"
                size="small"
                type="date"
                sx={{ width: "150px" }}
                slotProps={{
                  input: {
                    sx: {
                      paddingRight: { xs: "10px", md: 0 },
                    },
                  },
                }}
                defaultValue={format(new Date(), "yyyy-MM-dd")}
                required
              />
              <Box sx={{ display: "flex", marginLeft: { xs: 1, md: 0 } }}>
                <Autocomplete
                  options={hours}
                  getOptionLabel={(option) => pad(option)}
                  disableClearable
                  id="fromHours"
                  size="small"
                  defaultValue={parseInt(new Date().getHours())}
                  sx={{ width: 80 }}
                  renderInput={(params) => <TextField {...params} required />}
                />
                <Autocomplete
                  options={minutes}
                  getOptionLabel={(option) => pad(option)}
                  disableClearable
                  id="fromMinutes"
                  size="small"
                  defaultValue={parseInt(new Date().getMinutes())}
                  sx={{ width: 80, marginLeft: 1 }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </Box>
              <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                <TextField
                  sx={{
                    width: "50px",
                  }}
                  slotProps={{
                    input: {
                      inputProps: { min: 0 },
                    },
                  }}
                  size="small"
                  onFocus={(e) => e.target.select()}
                  value={addHours}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setAddHours(e.target.value);
                    }
                  }}
                  required
                />
                <Typography sx={{ marginLeft: 1 }}>h</Typography>
                <TextField
                  sx={{
                    marginLeft: 1,
                    width: "50px",
                  }}
                  slotProps={{
                    input: {
                      inputProps: { min: 0 },
                    },
                  }}
                  size="small"
                  onFocus={(e) => e.target.select()}
                  value={addMinutes}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setAddMinutes(e.target.value);
                    }
                  }}
                  required
                />
                <Typography sx={{ marginLeft: 1 }}>m</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", md: "end" },
                px: 2,
                mt: 2,
              }}
            >
              <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
                <TextField
                  sx={{
                    width: "50px",
                    userSelect: "none",
                  }}
                  slotProps={{
                    input: {
                      inputProps: { min: 0 },
                    },
                  }}
                  size="small"
                  onFocus={(e) => {
                    e.preventDefault();
                    e.target.select();
                  }}
                  value={addHours}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setAddHours(e.target.value);
                    }
                  }}
                  required
                />
                <Typography sx={{ marginLeft: 1 }}>h</Typography>
                <TextField
                  sx={{
                    marginLeft: 1,
                    width: "50px",
                    userSelect: "none",
                  }}
                  slotProps={{
                    input: {
                      inputProps: { min: 0 },
                    },
                  }}
                  size="small"
                  onFocus={(e) => {
                    e.preventDefault();
                    e.target.select();
                  }}
                  value={addMinutes}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setAddMinutes(e.target.value);
                    }
                  }}
                  required
                />
                <Typography sx={{ marginLeft: 1 }}>m</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Tooltip title="Add">
                  <IconButton type="submit">
                    <CheckRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton onClick={() => setOpenCreateNewLog(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

EditTimeLog.propTypes = {
  timeLogs: array,
  ticket_id: number,
  analyst_id: number,
  myTime: number,
};

export { EditTimeLog };
