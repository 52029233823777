import { Avatar, Badge, Box, Collapse, Divider, Drawer, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import MessageIcon from "@mui/icons-material/Message";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import { useNavigate, useLocation } from "react-router-dom";
import { stringIcon } from "src/utils/createIcon";
import { logout } from "src/api/logout";
import logos from "src/images";
import Cookies from "js-cookie";
import { stringToColor } from "src/utils/stringToColor";
import { Notification } from "src/components/Notification";
import { instances } from "src/helpers/instances";
import { bool, func } from "prop-types";

const MobileMenu = ({ handleClose, open }) => {
  const [checked, setChecked] = useState("");
  const [avatar, setAvatar] = useState("");
  const [instance, setInstance] = useState("");
  const { all, count } = useSelector((state) => state.number);
  const { user } = useSelector((state) => state.user);
  const { departments } = useSelector((state) => state.departments);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setInstance(instances[window.location.origin]);
  }, []);

  useEffect(() => {
    const resp = returnGravatar(user.email);
    setAvatar(resp);
  }, [user]);

  const changePage = async (newPage) => {
    let page = newPage;
    if (location.pathname.includes("filter")) {
      const params = location.search;
      page = `${page}/filter${params}`;
    }
    navigate(page);
    handleClose();
  };

  const handleChange = (state) => {
    if (state === checked) {
      setChecked("");
    } else {
      setChecked(state);
    }
  };

  const handleLogout = async () => {
    const response = await logout();
    localStorage.removeItem(`${instance}_refresh_key`);
    Cookies.remove(`${instance}_access_key`);
    if (response.status === 204) {
      window.location.href = "/";
    }
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      transitionDuration={{ enter: 200, exit: 100 }}
      sx={{ display: { md: "none" } }}
    >
      <Box
        sx={{
          width: "70vw",
          display: "flex",
          flexDirection: "column",
          marginTop: 2,
        }}
      >
        <img
          src={logos[instance]}
          style={{
            alignSelf: "center",
            maxWidth: "260px",
            maxHeight: "40px",
            cursor: "pointer",
            paddingBottom: 16,
          }}
          alt="Logo"
          onClick={() => {
            if (user.departments?.length > 1) {
              const dep = departments?.filter((dep) => dep.id === user.default_dep_id)[0];
              if (!dep) {
                navigate("/All");
              } else {
                navigate(`/${dep.title.replaceAll(" ", "")}`);
              }
            } else {
              const dep = departments?.filter(
                (dep) => dep.id === user.departments[0].department_id
              )[0];
              navigate(`/${dep.title.replaceAll(" ", "")}`);
            }
          }}
        />
        {user.departments?.length > 1 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "2%",
              background: location.pathname.includes("All") ? "#ebf7f5" : undefined,
            }}
            onClick={() => changePage("/")}
          >
            <Box
              sx={{
                height: "100%",
                borderLeft: location.pathname.includes("/All") ? "4px solid" : undefined,
                borderColor: stringToColor("All"),
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                pt: 1,
                pb: 1,
                ml: location.pathname.includes("All") ? 2 : 2.5,
                alignItems: "center",
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Notification>{all ?? undefined}</Notification>}
              >
                <Avatar {...stringIcon("All")} />
              </Badge>
              <Typography variant="p" sx={{ marginLeft: 2, fontSize: 20 }}>
                All
              </Typography>
            </Box>
          </Box>
        )}
        {departments.map((department) => {
          if (
            !user.departments?.filter((dep) => dep.department_id === department.id)[0] &&
            department.title !== "SPAM"
          ) {
            return <div key={department.id}></div>;
          }
          const dep = count?.filter((number) => number.department_id === department.id)[0];
          return (
            <Box
              key={department.id}
              sx={{
                display:
                  department.title.replaceAll(" ", "").toLowerCase() !== "spam"
                    ? "flex"
                    : dep && (dep.openNumber > 0 || dep.onHoldNumber > 0)
                      ? "flex"
                      : "none",
                background: location.pathname.includes(department.title.replace(/\s/g, ""))
                  ? "#ebf7f5"
                  : undefined,
              }}
              onClick={() => changePage(department.title.replace(/\s/g, ""))}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "2%",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    borderLeft: location.pathname.includes(department.title.replace(/\s/g, ""))
                      ? "4px solid"
                      : undefined,
                    borderColor: stringToColor(department.title),
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    pt: 1,
                    pb: 1,
                    ml: location.pathname.includes(department.title.replace(/\s/g, "")) ? 2 : 2.5,
                    alignItems: "center",
                  }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Notification>{dep?.openNumber !== 0 ? dep?.openNumber : null}</Notification>
                    }
                  >
                    <Avatar {...stringIcon(department.title)} />
                  </Badge>
                  <Typography variant="p" sx={{ marginLeft: 2, fontSize: 20 }}>
                    {department.title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "2%",
            background: location.pathname.includes("cannedMessages") ? "#f7fcfb" : undefined,
          }}
          onClick={() => changePage("/cannedMessages")}
        >
          <Box
            sx={{
              height: "100%",
              borderLeft: location.pathname.includes("cannedMessages") ? "4px solid" : undefined,
              borderColor: "#424242",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              pt: 1,
              pb: 1,
              ml: location.pathname.includes("All") ? 2 : 2.5,
              alignItems: "center",
            }}
          >
            <MessageIcon sx={{ fontSize: 30, marginTop: 0.5, color: "#424242" }} />
            <Typography variant="p" sx={{ marginLeft: 2, fontSize: 20 }}>
              Canned Messages
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            width: "96%",
            marginTop: 2,
            marginLeft: "4%",
            paddingBottom: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => handleChange("profile")}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar {...stringAvatar(`${user.first_name} ${user.last_name}`)} src={avatar} />
              <Typography variant="h6" sx={{ marginLeft: 2 }}>
                {user.first_name} {user.last_name}
              </Typography>
            </Box>
            {checked === "profile" ? (
              <ExpandLessIcon fontSize="medium" sx={{ marginRight: "4%" }} />
            ) : (
              <ExpandMoreIcon fontSize="medium" sx={{ marginRight: "4%" }} />
            )}
          </Box>
          <Collapse in={checked === "profile"} timeout={{ enter: 100, exit: 50 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                marginTop: 1,
              }}
              onClick={() => changePage("/profile")}
            >
              <AccountCircleIcon sx={{ color: "#424242", fontSize: 30 }} />
              <Typography variant="p" sx={{ marginLeft: 2, fontSize: 20 }}>
                Profile
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                marginTop: 1,
              }}
              onClick={() => changePage("/changePassword")}
            >
              <LockIcon sx={{ color: "#424242", fontSize: 30 }} />
              <Typography variant="p" sx={{ marginLeft: 2, fontSize: 20 }}>
                Change Password
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: 1 }}
              onClick={handleLogout}
            >
              <LogoutIcon sx={{ color: "#424242", fontSize: 30 }} />
              <Typography variant="p" sx={{ marginLeft: 2, fontSize: 20 }}>
                Logout
              </Typography>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Drawer>
  );
};

MobileMenu.propTypes = {
  handleClose: func,
  open: bool,
};

export { MobileMenu };
