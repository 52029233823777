import { Box, Button, Typography, TextField } from "@mui/material";
import React from "react";
import { InputBase } from "src/components/InputBase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "src/api/login";
import { setUser } from "src/redux/actions/seUser";
import { setDepartments } from "src/redux/actions/setDepartments";
import { useRef } from "react";
import { useEffect } from "react";
import { setPreferences } from "src/redux/actions/preferencesActions";

const Auth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const emailRef = useRef(null);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    const user = { email: event.target.email.value, password: event.target.password.value };
    const response = await login(user);
    if (response.status > 205) {
      return;
    }
    await dispatch(setDepartments(response.data.departments));
    await dispatch(setPreferences(response.data.preferences));
    await dispatch(setUser(response.data.user));
    const redirect = searchParams.get("redirect");
    if (redirect && response.data.user) {
      navigate(redirect);
    } else {
      if (response.data.user.departments.length === 1) {
        const dep = [...response.data.departments].filter(
          (dep) => dep.id === response.data.user.departments[0].department_id
        )[0];
        navigate(`/${dep.title.replaceAll(" ", "")}`);
      } else if (response.data.user.default_dep_id !== 0) {
        const dep = [...response.data.departments].filter(
          (dep) => dep.id === response.data.user.default_dep_id
        )[0];

        navigate(`/${dep.title.replaceAll(" ", "")}`);
      }
    }
  };

  return (
    <>
      <InputBase>
        <Box
          sx={{
            marginTop: "60px",
            marginLeft: "35px",
            marginRight: "35px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "40px",
          }}
        >
          <Typography variant="h5">Welcome back!</Typography>
          <Typography variant="p">Please enter your account details.</Typography>
          <form onSubmit={handleLogin}>
            <TextField
              inputRef={emailRef}
              slotProps={{
                inputLabel: {
                  sx: { color: "black" },
                },
              }}
              sx={{ marginTop: 3, width: "100%", "& ::placeholder": { opacity: 0.9 } }}
              required
              id="email"
              label="Email"
              placeholder="Enter your email"
              type="email"
            />
            <TextField
              sx={{ marginTop: 3, width: "100%", "& ::placeholder": { opacity: 0.9 } }}
              slotProps={{
                inputLabel: {
                  sx: { color: "black" },
                },
              }}
              required
              id="password"
              label="Password"
              placeholder="Enter your password"
              type="password"
            />
            <Button
              variant="contained"
              sx={{ width: "100%", marginTop: 5, height: 50, fontSize: 18, background: "#267aad" }}
              type="submit"
            >
              Log in
            </Button>
          </form>
          <Typography
            sx={{
              textTransform: "none",
              color: "black",
              fontSize: 17,
              marginTop: 2,
              alignSelf: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/forgotPassword")}
          >
            Forgot Password?
          </Typography>
        </Box>
      </InputBase>
    </>
  );
};

export { Auth };
