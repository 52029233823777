import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { activityHistoryRequest } from "src/api/activityHistoryRequest";
import { style } from "src/styles/modalStyle";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { number, object } from "prop-types";

const ActivityHistory = ({ id, customer }) => {
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { operators } = useSelector((state) => state.operators);
  const { departments } = useSelector((state) => state.departments);

  const handleOpen = () => {
    getHistory();
    setOpen(true);
  };

  const getHistory = async () => {
    const response = await activityHistoryRequest(id);

    if (response.status === 200) {
      setHistory(response.data.history);
    }
  };

  return (
    <>
      <Tooltip title="Activity History">
        <IconButton
          sx={{ width: { xs: 30, md: 40 }, height: { xs: 30, md: 40 } }}
          onClick={handleOpen}
        >
          <HistoryRoundedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, background: "white", overflow: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: { md: "900px" },
              maxWidth: "90vw",
            }}
          >
            <Typography variant="h6">Activity History</Typography>
            <Tooltip title="Close">
              <IconButton sx={{ marginRight: -2, marginTop: -1 }} onClick={() => setOpen(false)}>
                <CloseRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>User</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      background:
                        item.user_type === 0
                          ? "#ebebeb"
                          : item.user_type === 1
                            ? "#f7fcfb"
                            : item.user_type === 3
                              ? "#dbca7f"
                              : undefined,
                    }}
                  >
                    <TableCell sx={{ width: "140px" }}>
                      <Typography>
                        {format(new Date(item.ts_activity * 1000), "dd MMM yyyy HH:mm")}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "140px" }}>
                      {item.user_type === 0 ? (
                        <Typography>System</Typography>
                      ) : item.user_type === 1 ? (
                        <Typography>
                          {customer.first_name} {customer.last_name}
                        </Typography>
                      ) : item.user_type === 4 ? (
                        <Typography>
                          {departments.filter((dep) => dep.id === item.user_id)[0].title}
                        </Typography>
                      ) : item.user_type === 5 ? (
                        <Typography>Administrator</Typography>
                      ) : item.user_type === 2 && item.user_id === user.id ? (
                        <Typography>
                          {user.first_name} {user.last_name}
                        </Typography>
                      ) : item.user_type === 2 && item.user_id !== user.id ? (
                        <Typography>
                          {operators.filter((op) => op.id === item.user_id)[0].first_name}{" "}
                          {operators.filter((op) => op.id === item.user_id)[0].last_name}
                        </Typography>
                      ) : item.user_type === 3 && item.user_id === user.id ? (
                        <Typography>
                          {user.first_name} {user.last_name}
                        </Typography>
                      ) : (
                        item.user_type === 3 &&
                        item.user_id !== user.id && (
                          <Typography>
                            {operators.filter((op) => op.id === item.user_id)[0].first_name}{" "}
                            {operators.filter((op) => op.id === item.user_id)[0].last_name}
                          </Typography>
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>{item.description}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
};

ActivityHistory.propTypes = {
  id: number,
  customer: object,
};

export { ActivityHistory };
