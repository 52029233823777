import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { WebCard } from "src/components/CannedMessagesForm/WebCard";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { MobileCard } from "src/components/CannedMessagesForm/MobileCard";
import { useSelector } from "react-redux";
import { DeleteCannedMessage } from "src/components/DeleteCannedMessage";
import { array } from "prop-types";

const CannedMessagesForm = ({ cannedMessages }) => {
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [length, setLength] = useState(0);
  const { user } = useSelector((state) => state.user);

  const handleSelectChange = (id) => {
    const temp = selected;
    const index = temp.indexOf(id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(id);
    }
    setSelected(temp);
    if (selected.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setLength(selected.length);
  };

  const handleSelectAll = () => {
    const temp = [];
    cannedMessages.forEach((element) => {
      if (element.analyst_id === user.id) {
        temp.push(element.id);
      }
    });
    setSelected(temp);
    setLength(temp.length);
  };

  const handleDeselectAll = () => {
    setSelected([]);
    setLength(0);
    setOpen(false);
  };

  const handleDeleteMessageChange = (state) => {
    setDeleteOpen(state);
  };

  return (
    <Box
      id="form"
      sx={{
        marginBottom: open ? { xs: "8vh", md: "20px" } : undefined,
        marginTop: 1,
        width: "100%",
      }}
    >
      {cannedMessages.length > 0 &&
        cannedMessages?.map((message) => (
          <Box key={message.id}>
            <WebCard
              message={message}
              checked={selected.includes(message.id)}
              handleSelectChange={handleSelectChange}
              setOpen={handleDeleteMessageChange}
            />
            <MobileCard
              message={message}
              checked={selected.includes(message.id)}
              handleSelectChange={handleSelectChange}
              selected={Boolean(length > 0)}
            />
          </Box>
        ))}
      {open && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
            height: { xs: "fit-content", md: "70px" },
            padding: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#f7f7f7",
            zIndex: 101,
            paddingBottom: { xs: 2, md: 1 },
            boxShadow: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { xs: "97%", md: "fit-content" },
              justifyContent: { xs: "space-between", md: "center" },
              paddingLeft: { xs: 1, md: 10 },
              paddingRight: { xs: 2, md: 0 },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: { xs: "100px", md: "110px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Typography sx={{ fontSize: 18, marginLeft: { xs: 1, md: 2 } }}>
                  {length} selected
                </Typography>
              </Box>
              {selected.length <
              cannedMessages.filter((message) => message.analyst_id === user.id).length ? (
                <Box>
                  <Tooltip title="Deselect All">
                    <IconButton onClick={handleDeselectAll}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Select All">
                    <IconButton onClick={handleSelectAll} sx={{ marginRight: 2 }}>
                      <CheckRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <Box>
                  <Tooltip title="Deselect All">
                    <IconButton onClick={handleDeselectAll}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Select All">
                    <span>
                      <IconButton disabled sx={{ marginRight: 2 }}>
                        <CheckRoundedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#383838",
                width: "fit-content",
                justifySelf: "end",
                p: { xs: 0, md: 1 },
                marginRight: 1.5,
                borderRadius: 3,
                transition: "background, 0.2s",
                "&:hover": { background: "#e6e6e6" },
              }}
              onClick={() => setDeleteOpen(true)}
            >
              <DeleteRoundedIcon />
              <Typography sx={{ marginLeft: 1, fontSize: 18 }}>Delete</Typography>
            </Box>
          </Box>
        </Box>
      )}

      <DeleteCannedMessage
        open={deleteOpen}
        setOpen={handleDeleteMessageChange}
        selected={selected}
        close={handleDeselectAll}
      />
    </Box>
  );
};

CannedMessagesForm.propTypes = {
  cannedMessages: array,
};

export { CannedMessagesForm };
