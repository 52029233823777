import { Autocomplete, Avatar, Box, MenuItem, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { array, func, string } from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCustomersRequest } from "src/api/getCustomersRequest";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import { instances } from "src/helpers/instances";

const versions = [
  { label: "LV1", value: "1" },
  { label: "LV2", value: "2" },
];

const MobileTicketsSearch = ({
  from,
  setFrom,
  assignee,
  setAssignee,
  status,
  setStatus,
  after,
  setAfter,
  before,
  setBefore,
  department,
  setDepartment,
  version,
  setVersion,
}) => {
  const { departments } = useSelector((state) => state.departments);
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);
  const [statuses] = useState(["Any", "Open", "On-hold", "Closed"]);
  const [customersTemp, setCustomersTemp] = useState([]);
  const [prev, setPrev] = useState("");
  const [operatorsTemp, setOperatorsTemp] = useState(operators);

  const showVersion = useMemo(() => {
    return instances[window.location.origin] === "lv";
  }, [instances]);

  useEffect(() => {
    if (!operators) {
      return;
    }
    setOperatorsTemp(operators);
  }, [operators]);

  const handleFromChange = (e, values) => {
    if (typeof values[values.length - 1] === "string") {
      return;
    }
    setFrom(values);
  };

  const changeDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const changeFromDate = (e) => {
    setAfter(e.target.value);
  };

  const changeToDate = (e) => {
    setBefore(e.target.value);
  };

  const changeVersion = (e) => {
    setVersion(e.target.value);
  };

  const handleInputChange = async (e) => {
    if (e.target.value === undefined || e.target.value.length < 3 || e.target.value === prev) {
      setPrev(e.target.value);
      if (customersTemp.length > 0) {
        setCustomersTemp([]);
      }
      return;
    }
    setPrev(e.target.value);
    const response = await getCustomersRequest(e.target.value);
    if (response.status === 200) {
      setCustomersTemp(response.data.customers);
    }
  };

  const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const processChange = debounce((e) => handleInputChange(e));

  const handleAssigneeChange = (e, values) => {
    setOperatorsTemp(operators);
    setAssignee(values);
  };

  const handleStatusesChange = (e, values) => {
    setStatus(values);
  };

  const handleAssigneeInputChange = (e) => {
    if (!e.target.value) {
      setOperatorsTemp(operators);
      return;
    }
    const newOptions = operators.filter((option) => {
      const name = `${option.first_name} ${option.last_name}`.toLowerCase();
      if (
        name.includes(e.target.value.toLowerCase()) ||
        option.email.includes(e.target.value.toLowerCase())
      ) {
        return option;
      } else return null;
    });
    setOperatorsTemp(newOptions);
  };

  return (
    <Box>
      <Autocomplete
        sx={{
          display: "inline-flex",
          minWidth: "250px",
          marginLeft: 2,
          ".MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap !important",
          },
        }}
        slotProps={{
          paper: {
            sx: {
              width: "90vw",
            },
          },
        }}
        multiple
        id="from"
        options={customersTemp}
        getOptionLabel={(option) => option.email}
        value={from || null}
        filterOptions={(options) => {
          return options;
        }}
        limitTags={1}
        autoHighlight={true}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.email === value.email}
        freeSolo
        onChange={handleFromChange}
        onInputChange={processChange}
        onBlur={() => {
          setCustomersTemp([]);
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            {...props}
            key={option.id}
          >
            <Box sx={{ marginLeft: 2 }}>
              <Avatar
                {...stringAvatar(
                  option.last_name ? `${option.first_name} ${option.last_name}` : option.email
                )}
                src={returnGravatar(option.email)}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {option.first_name} {option.last_name}
              </Typography>
              <Typography variant="p">{option.email}</Typography>
            </Box>
          </Box>
        )}
        renderInput={(params) => <TextField {...params} label="From" />}
      />
      <TextField
        select
        id="department"
        sx={{ width: "180px", marginLeft: 2 }}
        value={department}
        label="Department"
        onChange={changeDepartment}
      >
        <MenuItem value="All">All</MenuItem>
        {departments?.map((department) => {
          if (
            !user?.departments?.filter((dep) => dep.department_id === department.id)[0] &&
            department.id !== 1
          ) {
            return <div key={department.id}></div>;
          }
          return (
            <MenuItem key={department.id} value={department.title.replaceAll(" ", "")}>
              {department.title}
            </MenuItem>
          );
        })}
      </TextField>
      <Autocomplete
        multiple
        id="assignee"
        sx={{
          display: "inline-flex",
          minWidth: "250px",
          marginLeft: 2,
          ".MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap !important",
          },
        }}
        options={operatorsTemp || []}
        limitTags={1}
        autoHighlight={true}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        filterSelectedOptions
        freeSolo
        value={assignee.length > 0 && assignee[0] !== null ? assignee : []}
        onChange={handleAssigneeChange}
        onInputChange={handleAssigneeInputChange}
        filterOptions={(options) => {
          if (assignee.length > 0 && assignee[0].first_name === "Unassigned") {
            return options?.filter((option) => option.first_name === "Unassigned");
          }
          if (assignee.length > 0 && assignee[0].first_name !== "Unassigned") {
            return options?.filter((option) => option.first_name !== "Unassigned");
          }
          return options;
        }}
        renderInput={(params) => <TextField {...params} label="Assignee" />}
      />
      {showVersion && (
        <TextField
          select
          id="version"
          sx={{ width: "180px", marginLeft: 2 }}
          value={version}
          label="Version"
          onChange={changeVersion}
        >
          <MenuItem value="any">Any</MenuItem>
          {versions?.map((version) => {
            return (
              <MenuItem key={version.value} value={version.value}>
                {version.label}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      <TextField
        type="date"
        id="fromDate"
        value={after}
        onChange={changeFromDate}
        slotProps={{
          input: {
            inputProps: {
              max: before ? before : format(new Date(), "yyyy-MM-dd"),
            },
            sx: { paddingRight: 10 },
          },
          inputLabel: {
            shrink: true,
          },
        }}
        sx={{ width: "150px", marginLeft: 2 }}
        label="Updated From"
      />
      <TextField
        type="date"
        id="fromDate"
        value={before}
        onChange={changeToDate}
        slotProps={{
          input: {
            inputProps: {
              min: after ?? undefined,
              max: format(new Date(), "yyyy-MM-dd"),
            },
            sx: { paddingRight: 10 },
          },
          inputLabel: {
            shrink: true,
          },
        }}
        sx={{ width: "150px", marginLeft: 2 }}
        label="Updated To"
      />
      <Autocomplete
        multiple
        id="status"
        sx={{
          display: "inline-flex",
          minWidth: "150px",
          marginLeft: 2,
          marginRight: 2,
          caretColor: "transparent",
          ".MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap !important",
          },
        }}
        onKeyDown={(e) => e.preventDefault()}
        options={statuses}
        limitTags={2}
        filterSelectedOptions
        value={status.filter((status) => !!status) || null}
        onChange={handleStatusesChange}
        filterOptions={(options) => {
          if (status.length > 0 && status.includes("Any")) {
            return options?.filter((option) => option === "Any");
          }
          if (status.length > 0 && !status.includes("Any")) {
            return options?.filter((option) => option !== "Any");
          }
          return options;
        }}
        renderInput={(params) => <TextField {...params} label="Status" />}
      />
    </Box>
  );
};

MobileTicketsSearch.propTypes = {
  from: array,
  setFrom: func,
  assignee: array,
  setAssignee: func,
  status: array,
  setStatus: func,
  after: string,
  setAfter: func,
  before: string,
  setBefore: func,
  department: string,
  setDepartment: func,
  version: string,
  setVersion: func,
};

export { MobileTicketsSearch };
