import { MenuItem, TextField } from "@mui/material";
import { bool, number } from "prop-types";
import React, { useState } from "react";
import { changeTicketsPriorityRequest } from "src/api/changeTicketsPriorityRequest";

const TicketPriorityChange = ({ priority, id, halfWidth }) => {
  const [priorities] = useState([
    { id: 1, title: "Low" },
    { id: 2, title: "Medium" },
    { id: 3, title: "High" },
    { id: 4, title: "Urgent" },
  ]);

  const handleChange = async (e) => {
    const data = {
      ids: [id],
      priority: e.target.value,
    };
    await changeTicketsPriorityRequest(data);
  };

  return (
    <TextField
      select
      size="small"
      sx={{ width: halfWidth ? "49%" : "120px", marginTop: 1 }}
      slotProps={{
        input: {
          inputProps: {
            sx: {
              fontSize: 14,
            },
          },
        },
      }}
      value={priority}
      onChange={handleChange}
      label="Priority"
    >
      {priorities.map((option) => (
        <MenuItem key={option.id} value={option.id} sx={{ fontSize: 14 }}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

TicketPriorityChange.propTypes = {
  priority: number,
  id: number,
  halfWidth: bool,
};

export { TicketPriorityChange };
