import { Box, Button, Modal, Typography } from "@mui/material";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import { style } from "src/styles/modalStyle";
import React, { useState } from "react";
import { changeTicketsStatusRequest } from "src/api/changeTicketsStatusRequest";
import { array, bool, func } from "prop-types";

const CloseTickets = ({ selected, close, small, setKeepOpen = () => {}, enableIcon, closeBar }) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ids: selected,
      status: "3",
    };
    await changeTicketsStatusRequest(data);
    setKeepOpen(false);
    setOpen(false);
    if (enableIcon) {
      enableIcon();
    }
    if (closeBar) {
      closeBar();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: small ? "100%" : "fit-content",
          justifyContent: "start",
          py: small ? 0.5 : 1,
          px: small ? 2 : 0.5,
          borderRadius: !small ? 3 : undefined,
          transition: "background, 0.2s",
          "&:hover": { background: !small ? "#e6e6e6" : undefined },
        }}
        onClick={() => {
          setOpen(true);
          if (close) {
            close();
          }
        }}
      >
        {!small && <ArchiveRoundedIcon />}
        <Typography sx={{ marginLeft: !small ? 1 : undefined, fontSize: 18 }}>
          {small ? "Close" : "CLOSE"}
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          if (enableIcon) {
            enableIcon();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onMouseOver={() => setKeepOpen(true)}
      >
        <Box component="form" onSubmit={handleSubmit} sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ alignSelf: "center", fontSize: 28 }}
          >
            Close {selected.length > 1 ? "Tickets" : "Ticket"}
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            Are you sure you want to close the selected {selected.length > 1 ? "tickets" : "ticket"}
            ?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 3 }}>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                setKeepOpen(false);
                setOpen(false);
                if (enableIcon) {
                  enableIcon();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                marginLeft: 2,
                background: "#d13838",
                "&:hover": { background: "#b32020" },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

CloseTickets.propTypes = {
  selected: array,
  close: func,
  small: bool,
  setKeepOpen: func,
  enableIcon: func,
  closeBar: func,
};

export { CloseTickets };
