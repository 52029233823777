import React, { useState } from "react";
import { func, object } from "prop-types";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { CopyTicketDetails } from "src/components/TicketsForm/CopyTicketDetails";
import { ReminderChip } from "src/components/ReminderChip";

export const SubjectField = ({ ticket, clearHover, setPreventAction }) => {
  const [hover, setHover] = useState(false);
  return (
    <Box
      sx={{ width: "40%", display: "flex", alingItems: "center", position: "relative" }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Stack spacing={0} direction="column">
        <Tooltip
          title={
            <Typography variant="p" fontSize={13}>
              {ticket?.messages[0].message.slice(0, 100)}
              {ticket?.messages[0].message.length > 100 ? "..." : ""}
            </Typography>
          }
        >
          <Typography
            sx={{
              overflow: "hidden",
              width: "100%",
              height: "27px",
              marginTop: "3px",
              color: ticket?.status_id === 3 ? "#8a8a8a" : "black",
              fontWeight:
                ticket.status_id !== 3 &&
                ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer
                  ? "bold"
                  : undefined,
            }}
          >
            [{ticket?.ticket_number}]: {ticket?.subject}
          </Typography>
        </Tooltip>
        {ticket.reminders && ticket.reminders.length > 0 && (
          <ReminderChip reminders={ticket.reminders} />
        )}
      </Stack>
      {hover && (
        <CopyTicketDetails
          bgColor={ticket?.status_id === 2 ? "#c4c4c4" : "#f7f7f7"}
          ticket={ticket}
          clearHover={() => {
            setHover(false);
            clearHover();
          }}
          setPreventAction={setPreventAction}
        />
      )}
    </Box>
  );
};

SubjectField.propTypes = {
  ticket: object,
  clearHover: func,
  setPreventAction: func,
};
