import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCustomersRequest } from "src/api/getCustomersRequest";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { AttachmentUploadCard } from "src/components/AttachmentUploadCard";
import { addTicketRequest } from "src/api/addTicket";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "src/utils/validateEmail";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { createCustomerRequest } from "src/api/createCustomerRequest";
import { addFiles } from "src/utils/addFiles";
import snackbarUtils from "src/utils/SnackbarUtils";

const AddTicketForm = () => {
  const [sendFrom, setSendFrom] = useState("customer");
  const [customersTemp, setCustomersTemp] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [input, setInput] = useState("");
  const [prev, setPrev] = useState(null);
  const [subject, setSubject] = useState("");
  const [priority, setPriority] = useState(1);
  const [department, setDepartment] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [newCustomer, setNew] = useState(false);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const { departments } = useSelector((state) => state.departments);
  const { user } = useSelector((state) => state.user);
  const { preferences } = useSelector((state) => state.preferences);
  const fileRef = useRef(null);
  const timeout = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!departments) {
      return;
    }
    let temp = [...departments];
    temp = temp
      .sort((a, b) => {
        return a.priority - b.priority;
      })
      .filter((dep) => dep.title.toLowerCase().replaceAll(" ", "") !== "spam")[0];
    setDepartment(temp.id);
  }, [departments]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!preferences) {
      return;
    }
    if (!preferences.default_ticket_priority_custom) {
      return;
    }
    if (preferences.default_ticket_priority_custom <= 0) {
      return;
    }
    setPriority(preferences.default_ticket_priority_custom);
  }, [user, preferences]);

  const handleCustomerChange = (e, values) => {
    if (values === 1) {
      handleCreate();
      return;
    }
    setNew(false);
    if (!values) {
      handleClear();
      return;
    }
    setCustomer(values);
    const inputTemp =
      values.first_name && values.last_name
        ? `${values.first_name} ${values.last_name}`
        : values.first_name && !values.last_name
          ? values.first_name
          : values.email
            ? values.email
            : "";
    setInput(inputTemp);
    setPrev(values);
  };

  const handleInputChange = async (e) => {
    if (!e?.target?.value) {
      setInput("");
      return;
    }
    setInput(e.target.value);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      if (e.target.value === undefined || e.target.value.length < 3) {
        if (customersTemp.length > 0) {
          setCustomersTemp([]);
        }
        return;
      }
      const response = await getCustomersRequest(e.target.value);
      if (response.status === 200) {
        setCustomersTemp(response.data.customers);
      }
    }, 300);
  };

  const initialGetCustomers = async () => {
    if (input && customersTemp.length === 0) {
      const response = await getCustomersRequest(input);
      if (response.status === 200) {
        setCustomersTemp(response.data.customers);
      }
    }
  };

  const handleFilesChange = (e) => {
    try {
      const newFiles = addFiles(preferences, files, e.target.files);
      setFiles(newFiles);
    } catch (err) {
      snackbarUtils.error(err.message);
      e.target.value = null;
    }
  };

  const removeFile = (id) => {
    let temp = files;

    temp = temp.filter((file) => file.id !== id);

    setFiles(temp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let temp = [];
    files.forEach((file) => {
      temp.push(file.file);
    });
    const data = new FormData();
    temp.forEach((element, index) => {
      data.append("file" + index, element);
    });
    data.append("message", message);
    data.append("sendFrom", sendFrom);
    data.append("customer", customer.id);
    data.append("subject", subject);
    data.append("priority", priority.toString());
    data.append("department", department);
    data.append("name", name);

    const response = await addTicketRequest(data);
    if (response.status === 200) {
      navigate("/");
    }
  };

  const handleClear = () => {
    setCustomer(null);
    setInput("");
    setCustomersTemp([]);
  };

  const handleCreate = async () => {
    const response = await createCustomerRequest(input);
    if (response.status === 200) {
      setCustomer(response.data.customer);
      setPrev(response.data.customer);
      setInput(response.data.customer.email);
      setNew(true);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ background: "#f3f3f3", p: 1.5, borderRadius: 2 }}
    >
      <Typography variant="h5">Add Ticket</Typography>
      <Box
        sx={{ display: "flex", width: "100%", mt: 1, flexDirection: { xs: "column", md: "row" } }}
      >
        <Box sx={{ display: "flex", width: { xs: "100%", md: newCustomer ? "70%" : "100%" } }}>
          <TextField
            select
            size="small"
            value={sendFrom}
            sx={{ minWidth: 90 }}
            onChange={(e) => setSendFrom(e.target.value)}
          >
            <MenuItem value="customer">From</MenuItem>
            <MenuItem value="operator">To</MenuItem>
          </TextField>
          <Autocomplete
            id="customer"
            options={customersTemp || []}
            getOptionLabel={(option) =>
              option.first_name && option.last_name
                ? `${option.first_name} ${option.last_name}`
                : option.first_name && !option.last_name
                  ? option.first_name
                  : option.email
                    ? option.email
                    : ""
            }
            autoHighlight={true}
            value={customer}
            filterOptions={(options) => {
              const results = options;

              if (results.length === 0 && ((customer && customer.email !== input) || !customer)) {
                if (validateEmail(input)) {
                  return [1];
                }
                return [];
              }

              return results;
            }}
            size="small"
            sx={{ width: "100%", ml: 1 }}
            slotProps={{
              paper: {
                sx: {
                  width: {
                    xs: "87vw",
                    md: "100%",
                  },
                  position: "absolute",
                  right: 0,
                },
              },
            }}
            isOptionEqualToValue={(option, value) => {
              return option.email === value.email;
            }}
            onChange={handleCustomerChange}
            onInputChange={handleInputChange}
            onFocus={initialGetCustomers}
            onBlur={() => {
              if (!prev) {
                return;
              }
              setCustomer(prev);
              const inputTemp =
                prev.first_name && prev.last_name
                  ? `${prev.first_name} ${prev.last_name}`
                  : prev.first_name && !prev.last_name
                    ? prev.first_name
                    : prev.email
                      ? prev.email
                      : "";
              setInput(inputTemp);
            }}
            inputValue={input}
            renderOption={(props, option) => {
              if (customersTemp.length <= 0) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                    {...props}
                    key={-1}
                  >
                    <Box
                      sx={{
                        width: 35,
                        height: 35,
                        borderRadius: 50,
                        background: "#2196f3",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AddRoundedIcon sx={{ color: "white" }} />
                    </Box>
                    <Typography variant="p" sx={{ marginLeft: 2, fontWeight: 300 }}>
                      Create customer <span style={{ fontStyle: "italic" }}>{input}</span>
                    </Typography>
                  </Box>
                );
              } else if (option !== 1) {
                return (
                  <Box
                    component="li"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                    {...props}
                    key={option.id}
                  >
                    <Box sx={{ marginLeft: 1 }}>
                      <Avatar
                        {...stringAvatar(
                          option.last_name
                            ? `${option.first_name} ${option.last_name}`
                            : option.email
                        )}
                        alt={
                          option.last_name
                            ? `${option.first_name} ${option.last_name}`
                            : option.email
                        }
                        src={returnGravatar(option.email)}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}>
                      <Typography variant="p" sx={{ fontWeight: "bold" }}>
                        {option.first_name} {option.last_name}
                      </Typography>
                      <Typography variant="p">{option.email}</Typography>
                    </Box>
                  </Box>
                );
              }
            }}
            renderInput={(params) => <TextField {...params} label="Customer" required />}
          />
        </Box>
        {newCustomer && (
          <TextField
            size="small"
            label="Name"
            sx={{ width: { xs: "100%", md: "30%" }, ml: { md: 1 }, mt: { xs: 2, md: 0 } }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", mt: 2 }}>
        <TextField
          select
          size="small"
          label="Priority"
          sx={{ width: "170px" }}
          required
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
        >
          <MenuItem value={1}>Low</MenuItem>
          <MenuItem value={2}>Medium</MenuItem>
          <MenuItem value={3}>High</MenuItem>
          <MenuItem value={4}>Urgent</MenuItem>
        </TextField>
        <TextField
          select
          size="small"
          label="Department"
          value={department}
          sx={{ marginLeft: 2, width: { xs: "100%", md: 200 } }}
          onChange={(e) => setDepartment(e.target.value)}
          required
        >
          {departments
            .filter((dep) => dep.title.replaceAll(" ", "").toLowerCase() !== "spam")
            .map((dep) => (
              <MenuItem key={dep.id} value={dep.id}>
                {dep.title}
              </MenuItem>
            ))
            .reverse()}
        </TextField>
      </Box>
      <TextField
        size="small"
        sx={{ width: "100%", mt: 2 }}
        label="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        required
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          borderRadius: 3,
          px: active ? "14px" : "15px",
          py: active ? "6px" : "7px",
          mt: 2,
          border: active ? "2px solid #1976d2" : "1px solid #bdbdbd",
          "&:hover": {
            border: !active ? "1px solid black" : "",
          },
        }}
      >
        <TextField
          variant="standard"
          placeholder="Message"
          size="small"
          required
          multiline
          sx={{ width: "100%", pt: 0 }}
          minRows={4}
          slotProps={{
            input: {
              disableUnderline: true,
            },
          }}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
          }}
        >
          {files.length > 0 &&
            files.map((file) => (
              <AttachmentUploadCard
                key={file.id}
                file={file}
                index={files.indexOf(file)}
                removeFile={removeFile}
              />
            ))}
        </Box>
      </Box>
      <Box sx={{ marginTop: 1, display: "flex", justifyContent: "end", alignItems: "center" }}>
        <input
          type="file"
          multiple
          onChange={handleFilesChange}
          ref={fileRef}
          style={{ display: "none" }}
        />
        <Tooltip title="Attach a File">
          <IconButton onClick={() => fileRef.current.click()}>
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          type="submit"
          sx={{
            borderRadius: 4,
            background: "#3d3d3d",
            marginTop: 0.5,
            marginLeft: 1,
            "&:hover": {
              background: "#262626",
            },
          }}
        >
          Post
        </Button>
      </Box>
    </Box>
  );
};

export { AddTicketForm };
